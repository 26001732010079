import React from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators,
  VehicleActionCreators,
  VehicleState
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  Modal,
  Typography
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { CloseAuctionRequestDto, MavoVehicleAuction, MavoVehicleAuctionOffer } from '../../interfaces/Vehicle';;
import * as R from 'ramda';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { MavoVehicleState } from '../../store/reducers/MavoVehicle';
import { VehicleAuctionActionCreators } from '../../store/actions/VehicleAuction';
import moment from 'moment';
import { ScaleLoader } from 'react-spinners';
import { AppUser } from '../../interfaces/AppUser';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import _ from 'lodash';
import NavigationIcon from '@material-ui/icons/Navigation';


interface IMavoAuctionDetailsFormState {
  isLoading: boolean;
  executing: boolean;  
  isOpen: boolean;
  dialogVisible: boolean;
  mavoVehicleAuction: MavoVehicleAuction;
  offers: MavoVehicleAuctionOffer[];
  maxOffers: MavoVehicleAuctionOffer[];
}

export interface ExternalMavoAuctionDetailsFormProps {
  mavoVehicleAuction: MavoVehicleAuction;
  closeDialog: () => void;
} 

type IMavoAuctionDetailsFormProps = ExternalMavoAuctionDetailsFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
  vehicleState: VehicleState;
  mavoVehicleState: MavoVehicleState;
  mavoVehicleAuctionState: MavoVehicleAuction;
} & typeof CaseSettingsActionCreators &
  typeof VehicleActionCreators &
  typeof MavoVehicleActionCreators &  typeof VehicleAuctionActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class MavoAuctionDetailsForm extends React.PureComponent<IMavoAuctionDetailsFormProps, IMavoAuctionDetailsFormState> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;
  private appUserService!: IAppUserService;

  static contextType = ApplicationContext;

  state = {
    isLoading: false,
    executing: false,
    isOpen: false,
    dialogVisible: false,
    mavoVehicleAuction: this.props.mavoVehicleAuction,
    offers: [] as MavoVehicleAuctionOffer[],
    maxOffers: [] as MavoVehicleAuctionOffer[]
  } as IMavoAuctionDetailsFormState;

  public  componentDidMount() {   
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadData();
      }
    );
  
  }

  private loadData = async () => {
    const mavoVehicleAuction = await this.vehicleService.GetMavoAuction(this.state.mavoVehicleAuction.id);
    if (mavoVehicleAuction.mavoVehicleAuctionOffers.length > 0) {
      const mavoVehicleAuctionOffers = _.cloneDeep(mavoVehicleAuction.mavoVehicleAuctionOffers);
      const users = await this.appUserService.GetUsersInfo(
        Array.from(new Set(mavoVehicleAuctionOffers.map((item) => item.createdBy)))
      );
      mavoVehicleAuctionOffers.forEach((offer) => {
        const user = users.find((item: AppUser) => item.id === offer.createdBy);
        offer.createdByUser = R.isNil(user) ? null : user;
      });
      mavoVehicleAuction.mavoVehicleAuctionOffers = _.cloneDeep(mavoVehicleAuctionOffers);
    }

    const maxOffers = mavoVehicleAuction.mavoVehicleAuctionOffers.filter(x => x.hasManualOffer);
    console.log('maxOffers', maxOffers);
    this.setState({
      isLoading: false,
      mavoVehicleAuction: mavoVehicleAuction,
      offers: mavoVehicleAuction.mavoVehicleAuctionOffers,
      maxOffers: maxOffers
    });

  }
  
  showOfferTimeline = () => {
    return this.state.offers.map((mavoVehicleAuctionOffer, index) => {
      return (
        <div key={index}>
          {mavoVehicleAuctionOffer.dateCreation ? moment.utc(mavoVehicleAuctionOffer.dateCreation).local().format(this.props.appState.longDateFormat) : ''}
          &nbsp;
          -
          &nbsp;
          {mavoVehicleAuctionOffer.createdByUser ? mavoVehicleAuctionOffer.createdByUser.organization!.name : ''}
          &nbsp;
          :
          &nbsp;
          {mavoVehicleAuctionOffer.minimumOffer} 
          &nbsp;
          :
          {mavoVehicleAuctionOffer.hasManualOffer ? '' : ' * '}
      </div>
      );
    });
  };

  showBidOfferTimeline = () => {
    return this.state.maxOffers.map((mavoVehicleAuctionOffer, index) => {
      return (
        <div key={index}>
          {mavoVehicleAuctionOffer.dateCreation ? moment.utc(mavoVehicleAuctionOffer.dateCreation).local().format(this.props.appState.longDateFormat) : ''}
          &nbsp;
          /
          &nbsp;
          {mavoVehicleAuctionOffer.createdByUser ? mavoVehicleAuctionOffer.createdByUser.organization!.name : ''}
          &nbsp;
          =
          &nbsp;
          {mavoVehicleAuctionOffer.maximumOffer} 
      </div>
      );
    });
  };
  
  auctionCommentDetails = () => {
    return (
      <Grid container spacing={0} alignItems="flex-start">
        <Grid item className="d-inline-flex ml-2 mt-1 pr-3" style={{ display: 'flex' }}>
          <div style={{ marginRight: '10px', fontWeight: 'bold', color: 'black', whiteSpace: 'nowrap' }}>
            {this.translatorService.Tranlate('AUCTION_OTHER_DETAILS', 'Alte detalii') + ':'}
          </div>
          <div>
            {this.state.mavoVehicleAuction ? this.state.mavoVehicleAuction.details : ''}
          </div>
        </Grid>
      </Grid>
    );
  }  

  auctionDetails = () => {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-1 pr-3"> 
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('MAVO_VEHICLES_AUCTION_VIN', 'VIN') + ': '}{' '}
          </div>
          <div>
            &nbsp; {this.state.mavoVehicleAuction ? this.state.mavoVehicleAuction.mavoVehicle.vin : ''}
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-1 pr-3"> 
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('VEHICLES_VEHICLE_MAKE', 'Marca') + ': '}{' '}
          </div>
          <div>
            &nbsp; {this.state.mavoVehicleAuction.mavoVehicle.make?.displayName}
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-1 pr-3"> 
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('VEHICLES_VEHICLE_MODEL', 'Model') + ': '}{' '}
          </div>
          <div>
            &nbsp; {this.state.mavoVehicleAuction.mavoVehicle.model?.displayName}
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-1 pr-3"> 
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_STATE', 'Stare')}:
          </div>
          <div>
            &nbsp;{!R.isNil(this.state.mavoVehicleAuction.auctionStatus) ? this.state.mavoVehicleAuction.auctionStatus.displayName || '' : ''}
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-1 pr-3">
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('MAVO_VEHICLES_AUCTION_START_EURO_PRICE', 'Pret start licitatie (euro)')}:
          </div>
          <div>
          &nbsp;{this.state.mavoVehicleAuction && this.state.mavoVehicleAuction.startPrice} EUR
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-1 pr-3"> 
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('MAVO_VEHICLES_AUCTION_START_DATE', 'Data start') + ': '}{' '}
          </div>
          <div>
            &nbsp;
            {moment
              .utc(this.state.mavoVehicleAuction.startDate)
              .local()
              .format(this.props.appState.dateFormat)}
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-1 pr-3"> 
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('MAVO_VEHICLES_AUCTION_END_DATE', 'Data stop licitatie') + ': '}{' '}
          </div>
          <div>
            &nbsp;
            {moment(this.state.mavoVehicleAuction.endDate)
              .format(this.props.appState.longDateFormat)}
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-2 pr-3">
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('VEHICLE_AUCTION_MIN_DIFFERENCE', 'Suma minima peste ultima oferta')+ ': '}{' '}
          </div>
          <div>
            &nbsp;{this.state.mavoVehicleAuction.minDifference || ''}
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-2 pr-3">
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('VEHICLE_AUCTION_CURRENT_EURO_PRICE', 'Pret curent licitatie (euro)')+ ': '}{' '}
          </div>
          <div>
            &nbsp;{this.state.mavoVehicleAuction.currentPrice || ''}
          </div>
        </Grid>
      </Grid>
    );
  }

  
  handleOpenConfirmationDialog = () => {
    this.setState({
      isOpen: true,
      dialogVisible: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  closeAuction = async () => {
    try {
      const request = {
        auctionId: this.state.mavoVehicleAuction.id,
      } as CloseAuctionRequestDto;
      const result = await this.vehicleService.CloseAuction(request);
      this.handleCloseModal();
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }


  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appUserService = (this.context as AppContext).appUserService;

    return (
      <div>
        <React.Fragment>
          <Card className="mb-3 p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <Button
                  className="ml-2 bg-theme-primary"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    this.props.closeDialog();
                  }}
                >
                  <NavigationIcon />
                  {this.translatorService.Tranlate('AUCTION_DIALOG_BACK', 'Inapoi')}
                </Button>
              </div>
              <div>
                <Button
                  className="ml-2 bg-theme-primary"
                  variant="contained"
                  color="secondary"
                  onClick={this.handleOpenConfirmationDialog}
                >
                  {this.translatorService.Tranlate('AUCTION_DIALOG_CLOSED_BUTTON', 'Inchide licitatie')}
                </Button>
              </div>
            </div>
          </Card>
        </React.Fragment>
        <React.Fragment>
          <Card className="mb-3 p-3">
            <div className="d-flex flex-row text-center flex-wrap justify-content-center">
              <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
            </div>
           {!this.state.isLoading ? (
              <CardContent className="p-3">
                {this.auctionDetails()} 
                <Typography className="MuiTypography-h4 ml-2 mt-4" >
                  {this.translatorService.Tranlate(
                    'AUCTION_HISTORY',
                    'Istoric licitatie'
                  )}:
                </Typography>
                {this.state.offers && this.state.offers.length > 0 ? (
                  <div className="ml-2 mt-2 text-left">
                    {this.showOfferTimeline()}
                  </div>
                ) : (
                  <div>
                    <div className="d-inline-flex ml-2 mt-2 pr-3">
                      Nu exista oferte
                    </div>
                  </div>
                )}
                <Divider className="mt-4" />
                {this.auctionCommentDetails()}
                <Divider />
                <Typography className="MuiTypography-h4 ml-2 mt-4" >
                  {this.translatorService.Tranlate(
                    'AUCTION_MAX_BIDS',
                    'Lista oferte maxime (Biduri maxime)'
                  )}:
                </Typography>
                {this.state.maxOffers && this.state.maxOffers.length > 0 ? (
                  <div className="ml-2 mt-2 text-left">
                    {this.showBidOfferTimeline()}
                  </div>
                ) : (
                  <div>
                    <div className="d-inline-flex ml-2 mt-2 pr-3">
                      Nu exista oferte
                    </div>
                  </div>
                )}
              </CardContent>
            ): null}
          </Card>

          <Modal open={this.state.isOpen} onClose={this.handleCloseModal}>
            <Dialog open={this.state.dialogVisible} onClose={this.handleCloseModal}>
              <DialogContent>
                <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                  {`${this.translatorService.Tranlate(
                    'AUCTION_CLOSED_CONFIRMATION_MESSAGE',
                    'Sunteti sigur ca doriti sa inchideti licitatia?'
                  )} `}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={this.closeAuction}
                >
                  {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_YES', 'Da')}
                </Button>
                <Button onClick={this.handleCloseModal}>
                  {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'Nu')}
                </Button>
              </DialogActions>
            </Dialog>
          </Modal>
        </React.Fragment>
  
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: ExternalMavoAuctionDetailsFormProps) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle,
    mavoVehicleState: state.mavoVehicle,
    mavoVehicleAuctionState: state.mavoVehicleAuction,
  }),
{ ...CaseSettingsActionCreators, ...VehicleActionCreators, ...MavoVehicleActionCreators, ...VehicleAuctionActionCreators }
)(withSnackbar(MavoAuctionDetailsForm as any));
