import { Action, Reducer } from 'redux';
import * as ActionTypes from '../actions/ActionTypes';
import { MavoVehicle, MavoVehicleAttachment, MavoVehicleOption, VehicleConditionInfo, VehicleOptionInfo } from '../../interfaces/Vehicle';
import { MavoVehicleAction } from '../actions/MavoVehicle';

export interface MavoVehicleState {
  mavoVehicle: MavoVehicle;
}

const initialState: MavoVehicleState = {
  mavoVehicle: {
    id: 0,
    vin: '',
    make: null,
    makeId: null,
    model: null,
    modelId: null,
    versionItem: null,
    versionId: null,
    color: null,
    colorId: null,
    fuelType: null,
    fuelTypeId: null,
    bodyTypeId: null,
    bodyType: null,
    transmissionTypeId: null,
    transmissionType: null,
    provenance: null,
    provenanceId: null,
    firstRegistrationDate: null,
    fabricationYear: null,
    kilometersNr: null,
    power: null,
    horsePower: null,
    engineManufactureCode: null,
    accessories: null,
    isReserved: false,
    reservations: null,
    cylinderCapacity: null,
    supplier: null,
    location: null,
    htPurchaseRonPrice: null,
    ttcEvaluationEurPrice: null,
    nirNumber: null,
    nirDate: null,
    currentStatus: null,
    vehicleOptions: [] as MavoVehicleOption[],
    mavoVehicleAttachments: [] as MavoVehicleAttachment[],
    isMavoVehicle: true,
    vehicleType: null,
    vehicleTypeId: null,
    vehicleOptionInfo: {
      vehicleId: 0,
      engineCapacity: null,
      emissionsClass: null,
      doorCount: null,
      seatCount: null,
      driveType: null,
      registration: null,
      vehicleCondition: null,
      metallicColor: null,
      upholstery: null,
      upholsteryColor: null,
      previousOwnersCount: null,
      countryOfOrigin: null,
      alarm: false,
      antiTheft: false,
      abs: false,
      esp: false,
      driverFrontAirbag: false,
      passengerFrontAirbag: false,
      frontSideAirbags: false,
      frontCurtainAirbag: false,
      xenonHeadlights: false,
      alloyWheels: false,
      glassRoof: false,
      towHook: false,
      protectionFrame: false,
      steeringWheelControls: false,
      onBoardComputer: false,
      cruiseControl: false,
      lockableDifferential: false,
      particleFilter: false,
      electricMirrors: false,
      frontElectricWindows: false,
      rearElectricWindows: false,
      tintedWindows: false,
      heatedSeats: false,
      rainSensors: false,
      parkingSensors: false,
      powerSteering: false,
      sunroof: false,
      rightHandDrive: false,
      fogLights: false,
      airConditioning: false,
      radio: false,
      cdPlayer: false,
      videoScreen: false,
      gps: false
    } as VehicleOptionInfo,
    vehicleConditionInfo: {
      vehicleId: 0,
      frontWingLeftPaintId: null,
      frontWingLeftPaint: null,
      frontWingRightPaintId: null,
      frontWingRightPaint: null,
      rearWingLeftPaintId: null,
      rearWingLeftPaint: null,
      rearWingRightPaintId: null,
      rearWingRightPaint: null,
      frontDoorLeftPaintId: null,
      frontDoorLeftPaint: null,
      frontDoorRightPaintId: null,
      frontDoorRightPaint: null,
      rearDoorLeftPaintId: null,
      rearDoorLeftPaint: null,
      rearDoorRightPaintId: null,
      rearDoorRightPaint: null,
      hoodPaintId: null,
      hoodPaint: null,
      faceMaskPaintId: null,
      faceMaskPaint: null,
      frontBumperPaintId: null,
      frontBumperPaint: null,
      rearBumperPaintId: null,
      rearBumperPaint: null,
      trunkPaintId: null,
      trunkPaint: null,
      spoilerPaintId: null,
      spoilerPaint: null,
      roofPaintId: null,
      roofPaint: null,
      superstructurePaintId: null,
      superstructurePaint: null,
    
      // Tinichigerie + Vopsitorie (Bodywork + Painting)
      frontWingLeftBodyPaintId: null,
      frontWingLeftBodyPaint: null,
      frontWingRightBodyPaintId: null,
      frontWingRightBodyPaint: null,
      rearWingLeftBodyPaintId: null,
      rearWingLeftBodyPaint: null,
      rearWingRightBodyPaintId: null,
      rearWingRightBodyPaint: null,
      frontDoorLeftBodyPaintId: null,
      frontDoorLeftBodyPaint: null,
      frontDoorRightBodyPaintId: null,
      frontDoorRightBodyPaint: null,
      rearDoorLeftBodyPaintId: null,
      rearDoorLeftBodyPaint: null,
      rearDoorRightBodyPaintId: null,
      rearDoorRightBodyPaint: null,
      hoodBodyPaintId: null,
      hoodBodyPaint: null,
      faceMaskBodyPaintId: null,
      faceMaskBodyPaint: null,
      frontBumperBodyPaintId: null,
      frontBumperBodyPaint: null,
      rearBumperBodyPaintId: null,
      rearBumperBodyPaint: null,
      trunkBodyPaintId: null,
      trunkBodyPaint: null,
      spoilerBodyPaintId: null,
      spoilerBodyPaint: null,
      roofBodyPaintId: null,
      roofBodyPaint: null,
      superstructureBodyPaintId: null,
      superstructureBodyPaint: null,
    
      // Replacement & Painting (Inlcuire + vopsire)
      frontWingLeftReplacePaintId: null,
      frontWingLeftReplacePaint: null,
      frontWingRightReplacePaintId: null,
      frontWingRightReplacePaint: null,
      rearWingLeftReplacePaintId: null,
      rearWingLeftReplacePaint: null,
      rearWingRightReplacePaintId: null,
      rearWingRightReplacePaint: null,
      frontDoorLeftReplacePaintId: null,
      frontDoorLeftReplacePaint: null,
      frontDoorRightReplacePaintId: null,
      frontDoorRightReplacePaint: null,
      rearDoorLeftReplacePaintId: null,
      rearDoorLeftReplacePaint: null,
      rearDoorRightReplacePaintId: null,
      rearDoorRightReplacePaint: null,
      hoodReplacePaintId: null,
      hoodReplacePaint: null,
      faceMaskReplacePaintId: null,
      faceMaskReplacePaint: null,
      frontBumperReplacePaintId: null,
      frontBumperReplacePaint: null,
      rearBumperReplacePaintId: null,
      rearBumperReplacePaint: null,
      trunkReplacePaintId: null,
      trunkReplacePaint: null,
      spoilerReplacePaintId: null,
      spoilerReplacePaint: null,
      roofReplacePaintId: null,
      roofReplacePaint: null,
      superstructureReplacePaintId: null,
      superstructureReplacePaint: null,
      //Inlocuire/Reparatie
      frontLeftSideWindowToReplace: false,
      frontLeftSideWindowToRepair: false,
      frontRightSideWindowToReplace: false,
      frontRightSideWindowToRepair: false,
    
      rearLeftSideWindowToReplace: false,
      rearLeftSideWindowToRepair: false,
      rearRightSideWindowToReplace: false,
      rearRightSideWindowToRepair: false,
    
      leftSideMirrorToReplace: false,
      leftSideMirrorToRepair: false,
      rightSideMirrorToReplace: false,
      rightSideMirrorToRepair: false,
    
      leftWiperToReplace: false,
      leftWiperToRepair: false,
      rightWiperToReplace: false,
      rightWiperToRepair: false,
    
      leftProjectorToReplace: false,
      leftProjectorToRepair: false,
      rightProjectorToReplace: false,
      rightProjectorToRepair: false,
    
      leftTurnSignalToReplace: false,
      leftTurnSignalToRepair: false,
      rightTurnSignalToReplace: false,
      rightTurnSignalToRepair: false,
    
      leftHeadlightToReplace: false,
      leftHeadlightToRepair: false,
      rightHeadlightToReplace: false,
      rightHeadlightToRepair: false,
    
      leftBrakeLightsToReplace: false,
      leftBrakeLightsToRepair: false,
      rightBrakeLightsToReplace: false,
      rightBrakeLightsToRepair: false,
    
      rearWindshieldToReplace: false,
      rearWindshieldToRepair: false,
    
      windshieldToReplace: false,
      windshieldToRepair: false,
    
      hoodGrilleToReplace: false,
      hoodGrilleToRepair: false,
    
      //Mecanica
      motor: null,
      clutch: null,
      gearbox: null,
      steering: null,
      battery: null,
      exhaust: null,
      tires: null,
      driveShaft: null,
      leftCvJoints: null,
      rightCvJoints: null,
      leftShockAbsorbers: null,
      rightShockAbsorbers: null,
      frontBrakes: null,
      backBrakes: null,
      itpValidateDate: null,
    
      //Interior si accesorii
      leftSeatUpholstery: null,
      rightSeatUpholstery: null,
      rearSeatUpholstery: null,
      interiorMirror: null,
      firstAidKit: null,    
      bulbKit: null,  
      leftFrontHeadrest: null,     
      rightFrontHeadrest: null,
      rearHeadrest: null,
      spareTireGood: false,
      spareTireReplace: false,
      frontFloorMats: false,
      fireExtinguisher: false,
      wheelWrench: false,
      rearFloorMats: false,
      reflectiveTriangle: false, 
      carJack: false,
    } as VehicleConditionInfo
  } as unknown as MavoVehicle
};

export const MavoVehicleReducer: Reducer<MavoVehicleState | undefined> = (
  state: MavoVehicleState | undefined,
  incomingAction: Action
): MavoVehicleState | undefined => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as MavoVehicleAction;

  switch (action.type) {
    case ActionTypes.MAVO_VEHICLE_SET_VEHICLE:
      if (action.mavoVehicle === null)
        return {
          ...state,
          mavoVehicle: initialState.mavoVehicle
        };

      return {
        ...state,
        mavoVehicle: {
          ...action.mavoVehicle
        }
      };
    case ActionTypes.MAVO_VEHICLE_SET_VEHICLE_STATUS:
      return {
        ...state,
        mavoVehicle: {
          ...state.mavoVehicle!,
          currentStatus: { ...action.status }
        }
      };
  }

  return state;
};
