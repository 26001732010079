import React from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators,
  VehicleActionCreators,
  VehicleState
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Modal
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { MavoVehicleAuction, MavoVehicleAuctionOffer } from '../../interfaces/Vehicle';;
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { MavoVehicleState } from '../../store/reducers/MavoVehicle';
import { VehicleAuctionActionCreators } from '../../store/actions/VehicleAuction';
import moment from 'moment';
import { ScaleLoader } from 'react-spinners';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import _ from 'lodash';
import NavigationIcon from '@material-ui/icons/Navigation';
import RealTimeDate from './RealTimeDate';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import NumberFormat from 'react-number-format';
import { IReferential } from '../../interfaces/IReferential';

interface IMavoAuctionDetailsFormState {
  isLoading: boolean;
  executing: boolean;  
  isOpen: boolean;
  dialogVisible: boolean;
  selectedAuction: MavoVehicleAuction;
  mavoVehicleAuctionOffer: MavoVehicleAuctionOffer;
  isOfferLoading: boolean;  
}

export interface ExternalMavoAuctionDetailsFormProps {
  selectedAuction: MavoVehicleAuction;
  closeDialog: () => void;
  goToUserAuctions: () => void;
} 

type IMavoAuctionDetailsFormProps = ExternalMavoAuctionDetailsFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
  vehicleState: VehicleState;
  mavoVehicleState: MavoVehicleState;
  mavoVehicleAuctionState: MavoVehicleAuction;
} & typeof CaseSettingsActionCreators &
  typeof VehicleActionCreators &
  typeof MavoVehicleActionCreators &  typeof VehicleAuctionActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class AddAuctionOfferDialog extends React.PureComponent<IMavoAuctionDetailsFormProps, IMavoAuctionDetailsFormState> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;
  private appUserService!: IAppUserService;

  static contextType = ApplicationContext;

  state = {
    isLoading: false,
    executing: false,
    isOpen: false,
    dialogVisible: false,
    selectedAuction: this.props.selectedAuction,
    offers: [] as MavoVehicleAuctionOffer[],
    maxOffers: [] as MavoVehicleAuctionOffer[],
    mavoVehicleAuctionOffer: {
      mavoVehicleAuctionId: 0,
      id: 0
    } as MavoVehicleAuctionOffer,
    isOfferLoading: false
  } as IMavoAuctionDetailsFormState;

  public componentDidMount() {   
    ValidatorForm.addValidationRule('isValidMinimumOffer', (value) => {
      const minimumOffer = this.state.selectedAuction!.mavoVehicleAuctionOffers.length > 0  ? 
        this.state.selectedAuction!.minDifference + this.state.selectedAuction!.currentPrice : 
        this.state.selectedAuction!.startPrice;
      if (value < minimumOffer) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('isValidMaximumOffer', (value) => {
      if (value < this.state.mavoVehicleAuctionOffer!.minimumOffer) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('isGreaterThanMaxValue', (value) => {
      if (value >= 999999.99) {
        return false;
      }
      return true;
    });

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadData();
      }
    );
  }

  private loadData = async () => {
    const mavoVehicleAuction = await this.vehicleService.GetMavoAuction(this.state.selectedAuction.id);
    const minimumOffer = mavoVehicleAuction.mavoVehicleAuctionOffers.length > 0  ? 
      mavoVehicleAuction.minDifference + mavoVehicleAuction.currentPrice : 
      mavoVehicleAuction.startPrice;

    this.setState({
      isLoading: false,
      selectedAuction: mavoVehicleAuction,
      mavoVehicleAuctionOffer: {
        ...this.state.mavoVehicleAuctionOffer,
        mavoVehicleAuctionId: mavoVehicleAuction.id,
        minimumOffer: minimumOffer,
        details: ''
      }
    });

  }

  NumberDecimalFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };

  addAuctionOffer = async () => {
    try {
      this.setState({
        isOpen: false,
        executing: true,
        isOfferLoading: true
      });

      const mavoVehicleAuctionOffer = {
        ...this.state.mavoVehicleAuctionOffer,
        hasManualOffer: true
      };

      const result = await this.vehicleService.AddMavoAuctionOffer(mavoVehicleAuctionOffer);
      if (!result.success) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate(result.message, result.message), {
          variant: 'error'
        });
        
        if (result.currentPrice) {
          this.setState({
            executing: false,
            isOfferLoading: false,
            mavoVehicleAuctionOffer: {
              ...this.state.mavoVehicleAuctionOffer,
              minimumOffer: result.currentPrice + this.state.selectedAuction!.minDifference 
            }
          });
        }
        return;
      }

      this.setState({
        executing: false,
        isOfferLoading: false
      });

      this.props.goToUserAuctions();
      
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } 
  }
  
  renderAuctionDetails = () => {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-2 pr-3">
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('MAVO_AUCTION_REMAINING_TIME', 'Timp ramas')}:
          </div>
          <div>
            &nbsp;
            <RealTimeDate 
              endDate={this.state.selectedAuction!.endDate}
              showLastOffer={true}
              auctionStatus={this.state.selectedAuction!.auctionStatus}
            />
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-1 pr-3"> 
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_VIN_CODE', 'VIN')}: 
          </div>
          <div>
            &nbsp;{this.state.selectedAuction ? this.state.selectedAuction.mavoVehicle.vin : ''}
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-1 pr-3">
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('VEHICLES_VEHICLE_MAKE', 'Marca')}:
          </div>
          <div>
          &nbsp;{this.state.selectedAuction && this.state.selectedAuction.mavoVehicle.make ? this.state.selectedAuction.mavoVehicle.make.displayName : ''}
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-1 pr-3">
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('VEHICLES_VEHICLE_MODEL', 'Model')}:
          </div>
          <div>
          &nbsp;{this.state.selectedAuction && this.state.selectedAuction.mavoVehicle.model ? this.state.selectedAuction.mavoVehicle.model.displayName : ''}
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-1 pr-3">
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('MAVO_VEHICLES_AUCTION_START_PRICE', 'Pret start licitatie')}:
          </div>
          <div>
          &nbsp;{this.state.selectedAuction && this.state.selectedAuction.startPrice} EUR
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-1 pr-3">
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate(
              'MAVO_VEHICLES_AUCTION_START_DATE_1',
              'Data start licitatie'
            )}:
          </div>
          <div>
          &nbsp;{this.state.selectedAuction && this.state.selectedAuction.startDate  ?
            moment
              .utc(this.state.selectedAuction.startDate)
              .local()
              .format(this.props.appState.dateFormat) : null}
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-1 pr-3">
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate(
              'MAVO_VEHICLES_AUCTION_END_DATE',
              'Data stop licitatie'
            )}:
          </div>
          <div>
          &nbsp;{this.state.selectedAuction && this.state.selectedAuction.endDate ? moment(this.state.selectedAuction.endDate).format('DD-MM-YYYY HH:mm') : null}
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-2 pr-3">
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('VEHICLE_AUCTION_MIN_DIFFERENCE', 'Suma minima peste ultima oferta')}:
          </div>
          <div>
            &nbsp;{this.state.selectedAuction!.minDifference || ''}
          </div>
        </Grid>
        <Grid item xs={12} className="d-inline-flex ml-2 mt-2 pr-3">
          <div className="text-black font-weight-bold">
            {this.translatorService.Tranlate('VEHICLE_AUCTION_CURRENT_PRICE', 'Pret curent licitatie')}:
          </div>
          <div>
            &nbsp;{this.state.selectedAuction!.mavoVehicleAuctionOffers.length > 0 ? this.state.selectedAuction!.currentPrice : ''}
          </div>
        </Grid>
      </Grid>
    );
  }

  onChange =
    (fieldName: string, isNumber: boolean) => (e: any, newValue?: IReferential | null) => {
      isNumber
        ? this.setState({
          mavoVehicleAuctionOffer: {
              ...this.state.mavoVehicleAuctionOffer,
              [fieldName]: parseFloat(newValue || e.target.value)
            }
          })
        : this.setState({
          mavoVehicleAuctionOffer: {
              ...this.state.mavoVehicleAuctionOffer,
              [fieldName]: newValue || e.target.value
            }
          });
  };
  
  public renderOfferContent() {
    return (
      <ValidatorForm onSubmit={this.handleOpenConfirmationDialog} instantValidate={true}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextValidator
              fullWidth
              id="minimum-offer"
              className="m-2 pr-3"
              name="minimumOffer"
              placeholder={this.translatorService.Tranlate('VEHICLE_AUCTION_MINIMUM_OFFER', 'Oferta mea minima')}
              value={this.state.mavoVehicleAuctionOffer.minimumOffer || ''}
              onChange={(e: any) =>
                this.onChange('minimumOffer', true)(e)
              }
              label={this.translatorService.Tranlate('VEHICLE_AUCTION_MINIMUM_OFFER', 'Oferta mea minima')}
              validators={['isValidMinimumOffer', 'isGreaterThanMaxValue']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'MINIMUM_OFFER_ERROR_MESSAGE',
                  'Oferta minima trebuie sa fie mai mare decat pretul curent al licitatiei + suma minima peste ultima oferta: '
                ) + (this.state.selectedAuction!.mavoVehicleAuctionOffers.length > 0  ? 
                  this.state.selectedAuction!.minDifference + this.state.selectedAuction!.currentPrice : 
                  this.state.selectedAuction!.startPrice) + ' EUR',
                this.translatorService.Tranlate(
                  'GRATTER_THAN_MAX_VALUE_ERROR_MESSAGE',
                  'Pretul introdus este prea mare. Trebuie sa fie mica decat 999999.99'
                )
              ]}
              InputProps={{
                inputComponent: this.NumberDecimalFormatCustom
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              fullWidth
              id="maximum-offer"
              className="m-2 pr-3"
              name="maximumOffer"
              placeholder={this.translatorService.Tranlate('VEHICLE_AUCTION_MAXIMUM_OFFER', 'Oferta mea maxima')}
              value={this.state.mavoVehicleAuctionOffer.maximumOffer || ''}
              onChange={(e: any) =>
                this.onChange('maximumOffer', true)(e)
              }
              label={this.translatorService.Tranlate('VEHICLE_AUCTION_MAXIMUM_OFFER', 'Oferta mea maxima')}
              validators={['isValidMaximumOffer', 'isGreaterThanMaxValue']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'MAXIMUM_OFFER_ERROR_MESSAGE',
                  'Oferta maxima trebuie sa fie mai mare sau egala decat oferta mea minima: '
                ) + this.state.mavoVehicleAuctionOffer.minimumOffer + ' EUR',
                this.translatorService.Tranlate(
                  'GRATTER_THAN_MAX_VALUE_ERROR_MESSAGE',
                  'Pretul introdus este prea mare. Trebuie sa fie mica decat 999999.99'
                ) 
              ]}
              InputProps={{
                inputComponent: this.NumberDecimalFormatCustom
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              fullWidth
              id="details"
              className="m-2 pr-3"
              name="details"
              placeholder={this.translatorService.Tranlate(
                'MAVO_VEHICLES_OFFER_DETAILS',
                'Alte detalii'
              )}
              value={this.state.mavoVehicleAuctionOffer.details || ''}
              onChange={(e: any) => this.onChange('details', false)(e)}
              label={this.translatorService.Tranlate(
                'MAVO_VEHICLES_OFFER_DETAILS',
                'Alte detalii'
              )}
              multiline
              variant="outlined"
              rows={4}
            />
          </Grid>
        </Grid>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isOfferLoading} />
        </div>

        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.props.closeDialog}
          >
            {this.translatorService.Tranlate('MAVO_VEHICLE_IMPORT_CLOSE_BTN', 'Renunta')}
          </Button>
          <Button className="m-2" 
            variant="contained" 
            color="primary" 
            type="submit" 
            disabled={this.state.executing}>
            {this.translatorService.Tranlate('MAVO_VEHICLE_AUCTION_OFFER_BTN', 'Ofera')}
          </Button>
        </div>
    </ValidatorForm>
    );
  }
  
  handleOpenConfirmationDialog = () => {
    this.setState({
      isOpen: true,
      dialogVisible: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appUserService = (this.context as AppContext).appUserService;

    return (
      <div>
        <React.Fragment>
          <Card className="mb-3 p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <Button
                  className="ml-2 bg-theme-primary"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    this.props.closeDialog();
                  }}
                >
                  <NavigationIcon />
                  {this.translatorService.Tranlate('AUCTION_DIALOG_BACK', 'Inapoi')}
                </Button>
              </div>
            </div>
          </Card>
        </React.Fragment>
        <React.Fragment>
          <Card className="mb-3 p-3">
            <div className="d-flex flex-row text-center flex-wrap justify-content-center">
              <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
            </div>
           {!this.state.isLoading ? (
              <CardContent className="p-3">
                {this.renderAuctionDetails()}   
                {this.renderOfferContent()}             
              </CardContent>
            ): null}
          </Card>

          <Modal open={this.state.isOpen} onClose={this.handleCloseModal}>
            <Dialog open={this.state.isOpen} onClose={this.handleCloseModal}>
              <DialogContent>
                <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                  {`${this.translatorService.Tranlate(
                    'AUCTION_CONFIRMATION_MESSAGE',
                    'Confirmati licitarea conform conditiilor de licitare ?'
                  )} `}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={this.addAuctionOffer}
                >
                  {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_YES', 'Da')}
                </Button>
                <Button onClick={this.handleCloseModal}>
                  {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'Nu')}
                </Button>
              </DialogActions>
            </Dialog>
          </Modal>
        </React.Fragment>
  
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: ExternalMavoAuctionDetailsFormProps) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle,
    mavoVehicleState: state.mavoVehicle,
    mavoVehicleAuctionState: state.mavoVehicleAuction,
  }),
{ ...CaseSettingsActionCreators, ...VehicleActionCreators, ...MavoVehicleActionCreators, ...VehicleAuctionActionCreators }
)(withSnackbar(AddAuctionOfferDialog as any));
