import axios from 'axios';
import { Constants } from './Constants';
import { AuthService } from '../services/AuthService';

const instance = axios.create({
  baseURL: Constants.apiRoot,
  withCredentials: true // Permite trimiterea cookie-urilor in request-uri
});

instance.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers.locale = localStorage.getItem('locale');

    // config.paramsSerializer = (params) => qs.stringify(params, {
    //   serializeDate: (date: Date) =>  { debugger;
    //     return moment(date).format('YYYY-MM-DDTHH:mm:ssZ') }
    // });

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  function (response) {
    if (response.status === 204 && response.data === '') {
      response.data = null;
    }

    return response;
  },
  async function (error) {
    if (error?.response?.status === 401) {
      const services = new AuthService();
      return await services.loginAsync();
    }

    return Promise.reject(error);
  }
);

export default instance;
