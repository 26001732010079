import { IOrderService } from './Interfaces/IOrderService';
import {
  OrderFilters,
  OrderList,
  Order,
  OrderPart,
  PartToBeOrdered,
  OrderStatusHistory,
  OrderStatusChangeRequestDto,
  OrderFiltersResult,
  OrderPartsHistoryFilters,
  UpdateOrderAmountsRequestDto
} from '../interfaces/Order';
import { API } from '../helpers/API';
import { isNullOrUndefined } from 'util';
import AutoOrderHistory from '../components/order-parts-history/OrderPartsHistory';
import { CreatePartsToBeOrderedRequest, DeletePartToBeOrderedRequestDto } from '../interfaces/Case';

export class OrderService implements IOrderService {
  private baseController = '/case';

  public async GetOrders(orderFilters: OrderFilters ): Promise<OrderList> {
    return (
      await API.Post2Async<OrderFilters, OrderList>(`${this.baseController}/order`, orderFilters)
    ).data;
  }

  public async GetOrderFilters(orderFilter: OrderFilters): Promise<OrderFiltersResult> {
    return (
      await API.Post2Async<OrderFilters, OrderFiltersResult>(
        `${this.baseController}/order/filters`,
        orderFilter
      )
    ).data;
  }

  public async GetOrderParts(orderId: number | null): Promise<OrderPart[]> {
    return (await API.GetAsync<OrderPart[]>(`${this.baseController}/order/${orderId}/parts`)).data;
  }

  public async CreatePartsToBeOrdered(caseId: number | null, request: CreatePartsToBeOrderedRequest): Promise<PartToBeOrdered[]> {
    return (
      await API.Post2Async<CreatePartsToBeOrderedRequest, PartToBeOrdered[]>(
        `${this.baseController}/${caseId}/CreatePartsToBeOrdered`,
        request
      )
    ).data;
  }

  public async GetOrdersWithParts(caseId: number): Promise<Order[]> {
    return (await API.GetAsync<Order[]>(`${this.baseController}/${caseId}/ordersWithParts`)).data;
  }

  public async GetOrder(orderId: number, withParts: boolean): Promise<Order> {
    return (await API.GetAsync<Order>(`${this.baseController}/order/${orderId}/${withParts}`)).data;
  }

  public async GetOrderStatusHistories(orderId: number): Promise<OrderStatusHistory[]> {
    return (
      await API.GetAsync<OrderStatusHistory[]>(
        `${this.baseController}/order/${orderId}/statusHistories`
      )
    ).data;
  }

  public async GetPartsToBeOrdered(caseId: number): Promise<PartToBeOrdered[]> {
    return (
      await API.GetAsync<PartToBeOrdered[]>(`${this.baseController}/${caseId}/partsToBeOrdered`)
    ).data;
  }

  public async UpdatePartToBeOrdered(item: PartToBeOrdered): Promise<void> {
    await API.PutAsync<PartToBeOrdered>(`${this.baseController}/PartToBeOrdered`, item);
  }

  public async CreateOrder(caseId: number, supplierId: number): Promise<Order> {
    return (
      await API.Post2Async<any | null, Order>(
        `${this.baseController}/${caseId}/order/${supplierId}`,
        null
      )
    ).data;
  }

  public async DeleteOrderPart(partId: number): Promise<void> {
    await API.DeleteAsync(`${this.baseController}/order/part/${partId}`);
  }

  public async DeleteOrder(ordId: number): Promise<void> {
    await API.DeleteAsync(`${this.baseController}/order/${ordId}`);
  }

  public async DeletePartToBeOrdered(partId: number): Promise<void> {
    await API.DeleteAsync(`${this.baseController}/order/partToBeOrdered/${partId}`);
  }
  
  public async ChangeOrderPartStatus(partId: number, statusId: number): Promise<void> {
    await API.PutAsync<any>(`${this.baseController}/order/part/${partId}/status/${statusId}`, {});
  }

  public async ChangeOrderStatus(
    orderStatusChangeRequestDto: OrderStatusChangeRequestDto
  ): Promise<void> {
    const formData = new FormData();

    formData.append('statusId', orderStatusChangeRequestDto.statusId.toString());
    formData.append('orderId', orderStatusChangeRequestDto.orderId.toString());
    formData.append('comment', orderStatusChangeRequestDto.comment);
    formData.append('workflowId', orderStatusChangeRequestDto.workflowId.toString());
    if (!isNullOrUndefined(orderStatusChangeRequestDto.partsLocationOrganizationId)) {
      formData.append(
        'partsLocationOrganizationId',
        orderStatusChangeRequestDto.partsLocationOrganizationId?.toString()
      );
    }

    if (!isNullOrUndefined(orderStatusChangeRequestDto.partsOrganizationId)) {
      formData.append(
        'partsOrganizationId',
        orderStatusChangeRequestDto.partsOrganizationId?.toString()
      );
    }

    if (orderStatusChangeRequestDto.attachments.length !== 0) {
      formData.append(
        'attachmentTypeId',
        orderStatusChangeRequestDto.attachments[0].attachmentTypeId.toString()
      );
      formData.append(
        'attachmentTypeCode',
        orderStatusChangeRequestDto.attachments[0].attachmentType!.code!
      );
    }
    orderStatusChangeRequestDto.attachments.forEach((attach) => {
      formData.append('files', attach.file);
    });

    await API.PutAsync<FormData>(`${this.baseController}/order/status`, formData);
  }

  public async UpdateOrderPart(orderPart: OrderPart): Promise<void> {
    await API.PutAsync<OrderPart>(`${this.baseController}/orderPart`, orderPart);
  }

  public async GetOrderPartsHistory(orderPartsFilters: OrderPartsHistoryFilters): Promise<Blob>{
    return (
      await API.PostFileAsync<OrderPartsHistoryFilters, Blob>(
        `${this.baseController}/order/parts-history`,
        orderPartsFilters
      )
    ).data;
  }

  public async UpdateOrderAmounts(request: UpdateOrderAmountsRequestDto): Promise<Order> {
    return (
      await API.Post2Async<UpdateOrderAmountsRequestDto, Order>(
        `${this.baseController}/order/update-amounts`,
        request
      )
    ).data;
  }

  public async GetOrderWithVehicle(orderId: number): Promise<Order> {
    return (await API.GetAsync<Order>(`${this.baseController}/order/include-vehicle/${orderId}`)).data;
  }

  public async GetPartToBeOrdered(id: number): Promise<PartToBeOrdered> {
    return (
      await API.GetAsync<PartToBeOrdered>(`${this.baseController}/partToBeOrdered/${id}`)
    ).data;
  }

  public async DeleteCasePartTobeOrdered(request: DeletePartToBeOrderedRequestDto): Promise<void> {
    return (
      await API.Post2Async<DeletePartToBeOrderedRequestDto, void>(
        `${this.baseController}/partToBeOrdered/delete`,
        request
      )
    ).data;
  }
}
