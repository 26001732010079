import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import _ from 'lodash';
import { ApplicationState, CaseSettingsActionCreators, VehicleActionCreators } from '../../store';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { IReferential } from '../../interfaces/IReferential';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { MavoVehicleStatusCodeEnum, RefConstants, VehicleStatusCodeEnum, allowedMavoVehicleStatuses, disabledMavoVehicleStatuses, disabledNewMavoVehicleStatuses } from '../../helpers/Constants';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { MavoVehicleStatusDetailsProps } from '../cases/vehicleDetails/MavoVehicleStatusDetailsProps';
import { IMavoStatusDataContainerProps } from '../cases/vehicleDetails/IMavoStatusDataContainerProps';
import { Autocomplete } from '@material-ui/lab';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Utils } from '../../helpers/Utils';
import * as R from 'ramda';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MomentUtils from '@date-io/moment';
import NumberFormat from 'react-number-format';

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

interface IMavoStatusDataContainerState {
  isLoading: boolean;
  executing: boolean;
  isDisabled: boolean;
  bodyConditions: IReferential[];
}

const maxNumberOfCharacters = 100;

class MavoStatusDataContainer extends React.PureComponent<
  IMavoStatusDataContainerProps,
  IMavoStatusDataContainerState
> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;
  private appReferentialService!: IReferentialService;

  
  static contextType = ApplicationContext;
  static displayName = 'MavoStatusDataContainer';

  state = {
    isLoading: false,
    executing: false,
    isValuationOpen: false,
    isOpen: false,
    dialogVisible: false,
    isDisabled: false,
    bodyConditions: []
  } as IMavoStatusDataContainerState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadData();
      }
    );

    ValidatorForm.addValidationRule('maxLength', (value) => {
      if (!value) {
        return true;
      }

      if (value.length <= maxNumberOfCharacters) {
        return true;
      }

      return false;
    });

    ValidatorForm.addValidationRule('isGreaterThanMaxValue', (value) => {
      if (value >= 999999.99) {
        return false;
      }
      return true;
    });
  }

  loadData = async () => {
    const {
      bodyConditionRef
    } = RefConstants;
    const bodyConditions = await this.appReferentialService.Get(bodyConditionRef!.baseUrl);
    
    this.setState(
      {
        isLoading: false,
        isDisabled: this.isDisabled(),
        bodyConditions: bodyConditions
      }
    );
  };

  isDisabled = (): boolean => {
    if (this.props.disabled) {
      return true;
    }

    //1.2	Actualizare/adaugare
    if (this.props.mavoVehicleState.mavoVehicle.id === 0) {
      //Nu pot fi modificate informatii vehicul din tab-urile «Informatii generale», «Stare vehicul», 
      //«Despre vehicul (Dotari)» si «Date STOC» daca vehiculul este in una din starile : «Licitatie», «Comanda», «Aviz», «Factura»
      return  disabledNewMavoVehicleStatuses.includes(this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code ?? '') ? true : false;
    } else  {
      //daca vehiculul este in stare “Licitatie”, se pot actualiza doar informatiile «Stare vehicul», «Despre vehicul (Dotari)» si «Poze» 
      if (this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code === MavoVehicleStatusCodeEnum.LICITATIE.toString()) {
        return false;
      }
      //daca vehiculul este in orice alta stare («Comanda», «Aviz», «Factura»), nu se pot actualiza nici un fel de informatii referitoare la acest vehicul
      return  disabledMavoVehicleStatuses.includes(this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code ?? '') ? true : false;
    }
  };

  onChange =
    (fieldName: string, fieldNameId?: string) => (e: any, newValue?: IReferential | null) => {
      !R.isNil(fieldNameId)
        ? 
          this.props.setMavoVehicleObject({ 
            ...this.props.mavoVehicleState.mavoVehicle, 
            vehicleConditionInfo: {
              ...this.props.mavoVehicleState.mavoVehicle.vehicleConditionInfo, 
              [fieldName]: newValue || e.target.value,
              [fieldNameId]: Utils.GetIdOrNull(newValue)
            }
          })
        : 
          this.props.setMavoVehicleObject({ 
            ...this.props.mavoVehicleState.mavoVehicle, 
            vehicleConditionInfo: {
              ...this.props.mavoVehicleState.mavoVehicle.vehicleConditionInfo, 
              [fieldName]: newValue || e.target.value
            }
          })
  };

  onCheckBoxChange =
  (fieldName: string) => (checked: boolean) => {
    this.props.setMavoVehicleObject({ 
      ...this.props.mavoVehicleState.mavoVehicle, 
      vehicleConditionInfo: {
        ...this.props.mavoVehicleState.mavoVehicle.vehicleConditionInfo, 
        [fieldName]: checked
      }
    })
  };

  onRepairPriceChange = (e: any) => {
    const inputValue = e.target.value;
    const parsedValue = inputValue === "" || isNaN(inputValue) ? null : parseFloat(inputValue);

    this.props.setMavoVehicleObject({ 
      ...this.props.mavoVehicleState.mavoVehicle, 
      vehicleConditionInfo: {
        ...this.props.mavoVehicleState.mavoVehicle.vehicleConditionInfo, 
        repairCostPrice: parsedValue
      }
    })
  };


  handleToggleIsChecked =
  (fieldName: string, fieldName2: string) => (checked: boolean) => {
    this.props.setMavoVehicleObject({ 
      ...this.props.mavoVehicleState.mavoVehicle, 
      vehicleConditionInfo: {
        ...this.props.mavoVehicleState.mavoVehicle.vehicleConditionInfo, 
        [fieldName]: checked,
        [fieldName2]: !checked
      }
    })
  };

  renderBodySection = () => {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography component="div" variant="h4" align="left" className="mt-3 bg-first"  style={{ color: 'white', padding: '3px' }}>
            {this.translatorService.Tranlate('VEHICLE_CONDITION_BODY_TITLE', 'Caroserie')}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Grid item xs={3}>
          </Grid>
          <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500 }} className='mt-2'>
            {this.translatorService.Tranlate('VEHICLE_CONDITION_PAINTING_TITLE', 'Vopsire')}
          </Grid>
          <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500 }} className='mt-2'>
            {this.translatorService.Tranlate('VEHICLE_CONDITION_TINWARE_AND_PAINTING_TITLE', 'Tinichigherie & Vopsitorie')}
          </Grid>
          <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500 }} className='mt-2'>
            {this.translatorService.Tranlate('VEHICLE_CONDITION_REPLACE_AND_PAINTING_TITLE', 'Inlocuire & Vopsire')}
          </Grid>
        </Grid>
        
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Grid item xs={3}>
          </Grid>
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500, width: '50%' }} className='mt-2'>
              {this.translatorService.Tranlate('VEHICLE_CONDITION_LEFT', 'S')}
            </div>
            
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500, width: '50%'}} className='mt-2'>
              {this.translatorService.Tranlate('VEHICLE_CONDITION_RIGHT', 'D')}
            </div>
          </Grid>
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500, width: '50%' }} className='mt-2'>
              {this.translatorService.Tranlate('VEHICLE_CONDITION_LEFT', 'S')}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500, width: '50%' }} className='mt-2'>
              {this.translatorService.Tranlate('VEHICLE_CONDITION_RIGHT', 'D')}
            </div>
          </Grid>
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500, width: '50%' }} className='mt-2'>
              {this.translatorService.Tranlate('VEHICLE_CONDITION_LEFT', 'S')}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500, width: '50%' }} className='mt-2'>
              {this.translatorService.Tranlate('VEHICLE_CONDITION_RIGHT', 'D')}
            </div>
          </Grid>
        </Grid>
        
        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICLE_FRONT_WING', 'Aripa fata')}
          </Grid>
          {/* Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="frontWingLeftPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.frontWingLeftPaint}
                onChange={this.onChange('frontWingLeftPaint', 'frontWingLeftPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="frontWingLeftPaint"
                    value={this.props.conditionInfo.frontWingLeftPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="frontWingRightPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.frontWingRightPaint}
                onChange={this.onChange('frontWingRightPaint', 'frontWingRightPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="frontWingRightPaint"
                    value={this.props.conditionInfo.frontWingRightPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Tinichigerie & Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="frontWingLeftBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.frontWingLeftBodyPaint}
                onChange={this.onChange('frontWingLeftBodyPaint', 'frontWingLeftBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="frontWingLeftBodyPaint"
                    value={this.props.conditionInfo.frontWingLeftBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="frontWingRightBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.frontWingRightBodyPaint}
                onChange={this.onChange('frontWingRightBodyPaint', 'frontWingRightBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="frontWingRightBodyPaint"
                    value={this.props.conditionInfo.frontWingRightBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Inlocuire & Vopsire */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="frontWingLeftReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.frontWingLeftReplacePaint}
                onChange={this.onChange('frontWingLeftReplacePaint', 'frontWingLeftReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="frontWingLeftReplacePaint"
                    value={this.props.conditionInfo.frontWingLeftReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="frontWingRightReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.frontWingRightReplacePaint}
                onChange={this.onChange('frontWingRightReplacePaint', 'frontWingRightReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="frontWingRightReplacePaint"
                    value={this.props.conditionInfo.frontWingRightReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICLE_REAR_WING', 'Aripa spate')}
          </Grid>
          {/* Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="rearWingLeftPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.rearWingLeftPaint}
                onChange={this.onChange('rearWingLeftPaint', 'rearWingLeftPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="rearWingLeftPaint"
                    value={this.props.conditionInfo.rearWingLeftPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="rearWingRightPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.rearWingRightPaint}
                onChange={this.onChange('rearWingRightPaint', 'rearWingRightPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="rearWingRightPaint"
                    value={this.props.conditionInfo.rearWingRightPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Tinichigerie & Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="rearWingLeftBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.rearWingLeftBodyPaint}
                onChange={this.onChange('rearWingLeftBodyPaint', 'rearWingLeftBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="rearWingLeftBodyPaint"
                    value={this.props.conditionInfo.rearWingLeftBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="rearWingRightBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.rearWingRightBodyPaint}
                onChange={this.onChange('rearWingRightBodyPaint', 'rearWingRightBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="rearWingRightBodyPaint"
                    value={this.props.conditionInfo.rearWingRightBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Inlocuire & Vopsire */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="rearWingLeftReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.rearWingLeftReplacePaint}
                onChange={this.onChange('rearWingLeftReplacePaint', 'rearWingLeftReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="rearWingLeftReplacePaint"
                    value={this.props.conditionInfo.rearWingLeftReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="rearWingRightReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.rearWingRightReplacePaint}
                onChange={this.onChange('rearWingRightReplacePaint', 'rearWingRightReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="rearWingRightReplacePaint"
                    value={this.props.conditionInfo.rearWingRightReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICLE_FRONT_DOOR', 'Usa fata')}
          </Grid>
          {/* Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="frontDoorLeftPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.frontDoorLeftPaint}
                onChange={this.onChange('frontDoorLeftPaint', 'frontDoorLeftPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="frontDoorLeftPaint"
                    value={this.props.conditionInfo.frontDoorLeftPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="frontDoorRightPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.frontDoorRightPaint}
                onChange={this.onChange('frontDoorRightPaint', 'frontDoorRightPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="frontDoorRightPaint"
                    value={this.props.conditionInfo.frontDoorRightPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Tinichigerie & Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="frontDoorLeftBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.frontDoorLeftBodyPaint}
                onChange={this.onChange('frontDoorLeftBodyPaint', 'frontDoorLeftBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="frontDoorLeftBodyPaint"
                    value={this.props.conditionInfo.frontDoorLeftBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="frontDoorRightBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.frontDoorRightBodyPaint}
                onChange={this.onChange('frontDoorRightBodyPaint', 'frontDoorRightBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="frontDoorRightBodyPaint"
                    value={this.props.conditionInfo.frontDoorRightBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Inlocuire & Vopsire */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="frontDoorLeftReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.frontDoorLeftReplacePaint}
                onChange={this.onChange('frontDoorLeftReplacePaint', 'frontDoorLeftReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="frontDoorLeftReplacePaint"
                    value={this.props.conditionInfo.frontDoorLeftReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="frontDoorRightReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.frontDoorRightReplacePaint}
                onChange={this.onChange('frontDoorRightReplacePaint', 'frontDoorRightReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="frontDoorRightReplacePaint"
                    value={this.props.conditionInfo.frontDoorRightReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICLE_REAR_DOOR', 'Usa spate')}
          </Grid>
          {/* Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="rearDoorLeftPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.rearDoorLeftPaint}
                onChange={this.onChange('rearDoorLeftPaint', 'rearDoorLeftPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="rearDoorLeftPaint"
                    value={this.props.conditionInfo.rearDoorLeftPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="rearDoorRightPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.rearDoorRightPaint}
                onChange={this.onChange('rearDoorRightPaint', 'rearDoorRightPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="rearDoorRightPaint"
                    value={this.props.conditionInfo.rearDoorRightPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Tinichigerie & Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="rearDoorLeftBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.rearDoorLeftBodyPaint}
                onChange={this.onChange('rearDoorLeftBodyPaint', 'rearDoorLeftBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="rearDoorLeftBodyPaint"
                    value={this.props.conditionInfo.rearDoorLeftBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="rearDoorRightBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.rearDoorRightBodyPaint}
                onChange={this.onChange('rearDoorRightBodyPaint', 'rearDoorRightBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="rearDoorRightBodyPaint"
                    value={this.props.conditionInfo.rearDoorRightBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Inlocuire & Vopsire */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="rearDoorLeftReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.rearDoorLeftReplacePaint}
                onChange={this.onChange('rearDoorLeftReplacePaint', 'rearDoorLeftReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="rearDoorLeftReplacePaint"
                    value={this.props.conditionInfo.rearDoorLeftReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="rearDoorRightReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.rearDoorRightReplacePaint}
                onChange={this.onChange('rearDoorRightReplacePaint', 'rearDoorRightReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="rearDoorRightReplacePaint"
                    value={this.props.conditionInfo.rearDoorRightReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICLE_HOOD', 'Capota')}
          </Grid>
          {/* Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="hoodPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.hoodPaint}
                onChange={this.onChange('hoodPaint', 'hoodPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="hoodPaint"
                    value={this.props.conditionInfo.hoodPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Tinichigerie & Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="hoodBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.hoodBodyPaint}
                onChange={this.onChange('hoodBodyPaint', 'hoodBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="hoodBodyPaint"
                    value={this.props.conditionInfo.hoodBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Inlocuire & Vopsire */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="hoodReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.hoodReplacePaint}
                onChange={this.onChange('hoodReplacePaint', 'hoodReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="hoodReplacePaint"
                    value={this.props.conditionInfo.hoodReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICLE_FACE_MASK', 'Masca fata')}
          </Grid>
          {/* Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="faceMaskPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.faceMaskPaint}
                onChange={this.onChange('faceMaskPaint', 'faceMaskPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="faceMaskPaint"
                    value={this.props.conditionInfo.faceMaskPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Tinichigerie & Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="faceMaskBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.faceMaskBodyPaint}
                onChange={this.onChange('faceMaskBodyPaint', 'faceMaskBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="faceMaskBodyPaint"
                    value={this.props.conditionInfo.faceMaskBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Inlocuire & Vopsire */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="faceMaskReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.faceMaskReplacePaint}
                onChange={this.onChange('faceMaskReplacePaint', 'faceMaskReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="faceMaskReplacePaint"
                    value={this.props.conditionInfo.faceMaskReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICLE_FRONT_BUMPER', 'Bara fata')}
          </Grid>
          {/* Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="frontBumperPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.frontBumperPaint}
                onChange={this.onChange('frontBumperPaint', 'frontBumperPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="frontBumperPaint"
                    value={this.props.conditionInfo.frontBumperPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Tinichigerie & Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="frontBumperBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.frontBumperBodyPaint}
                onChange={this.onChange('frontBumperBodyPaint', 'frontBumperBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="frontBumperBodyPaint"
                    value={this.props.conditionInfo.frontBumperBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Inlocuire & Vopsire */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="frontBumperReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.frontBumperReplacePaint}
                onChange={this.onChange('frontBumperReplacePaint', 'frontBumperReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="frontBumperReplacePaint"
                    value={this.props.conditionInfo.frontBumperReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICLE_RARE_BUMPER', 'Bara spate')}
          </Grid>
          {/* Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="rearBumperPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.rearBumperPaint}
                onChange={this.onChange('rearBumperPaint', 'rearBumperPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="rearBumperPaint"
                    value={this.props.conditionInfo.rearBumperPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Tinichigerie & Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="rearBumperBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.rearBumperBodyPaint}
                onChange={this.onChange('rearBumperBodyPaint', 'rearBumperBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="rearBumperBodyPaint"
                    value={this.props.conditionInfo.rearBumperBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Inlocuire & Vopsire */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="rearBumperReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.rearBumperReplacePaint}
                onChange={this.onChange('rearBumperReplacePaint', 'rearBumperReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="rearBumperReplacePaint"
                    value={this.props.conditionInfo.rearBumperReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICLE_TRUNK', 'Haion')}
          </Grid>
          {/* Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="trunkPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.trunkPaint}
                onChange={this.onChange('trunkPaint', 'trunkPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="trunkPaint"
                    value={this.props.conditionInfo.trunkPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Tinichigerie & Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="trunkBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.trunkBodyPaint}
                onChange={this.onChange('trunkBodyPaint', 'trunkBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="trunkBodyPaint"
                    value={this.props.conditionInfo.trunkBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Inlocuire & Vopsire */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="trunkReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.trunkReplacePaint}
                onChange={this.onChange('trunkReplacePaint', 'trunkReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="trunkReplacePaint"
                    value={this.props.conditionInfo.trunkReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICLE_SPOILER', 'Eleron')}
          </Grid>
          {/* Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="spoilerPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.spoilerPaint}
                onChange={this.onChange('spoilerPaint', 'spoilerPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="spoilerPaint"
                    value={this.props.conditionInfo.spoilerPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Tinichigerie & Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="spoilerBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.spoilerBodyPaint}
                onChange={this.onChange('spoilerBodyPaint', 'spoilerBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="spoilerBodyPaint"
                    value={this.props.conditionInfo.spoilerBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Inlocuire & Vopsire */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="spoilerReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.spoilerReplacePaint}
                onChange={this.onChange('spoilerReplacePaint', 'spoilerReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="spoilerReplacePaint"
                    value={this.props.conditionInfo.spoilerReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICLE_ROOF', 'Pavilion')}
          </Grid>
          {/* Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="roofPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.roofPaint}
                onChange={this.onChange('roofPaint', 'roofPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="roofPaint"
                    value={this.props.conditionInfo.roofPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Tinichigerie & Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="roofBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.roofBodyPaint}
                onChange={this.onChange('roofBodyPaint', 'roofBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="roofBodyPaint"
                    value={this.props.conditionInfo.roofBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Inlocuire & Vopsire */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="roofReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.roofReplacePaint}
                onChange={this.onChange('roofReplacePaint', 'roofReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="roofReplacePaint"
                    value={this.props.conditionInfo.roofReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICLE_SUPERSTRUCTURE', 'Suprastructura')}
          </Grid>
          {/* Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="superstructurePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.superstructurePaint}
                onChange={this.onChange('superstructurePaint', 'superstructurePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="superstructurePaint"
                    value={this.props.conditionInfo.superstructurePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Tinichigerie & Vopsitorie */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="superstructureBodyPaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.superstructureBodyPaint}
                onChange={this.onChange('superstructureBodyPaint', 'superstructureBodyPaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="superstructureBodyPaint"
                    value={this.props.conditionInfo.superstructureBodyPaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
          {/* Inlocuire & Vopsire */}
          <Grid item xs={3} style={{ display: 'flex'}}>
            <div style={{width: '50%'}}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="superstructureReplacePaint"
                className='p-1 mr-1'
                options={this.state.bodyConditions}
                value={this.props.conditionInfo.superstructureReplacePaint}
                onChange={this.onChange('superstructureReplacePaint', 'superstructureReplacePaintId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="superstructureReplacePaint"
                    value={this.props.conditionInfo.superstructureReplacePaint}
                    fullWidth
                    placeholder={this.translatorService.Tranlate('VEHICLE_PLEASE_SELECT', 'Selecteaza')}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500 }} className='mt-3 mb-3'>
          {this.translatorService.Tranlate('VEHICLE_REPLACE_REPAIR_TITLE', 'Inlocuire / Reparatie')}
        </Grid>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Grid item xs={3}>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500}}>
            {this.translatorService.Tranlate('VEHICLE_CONDITION_LEFT', 'S')}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500}}>
            {this.translatorService.Tranlate('VEHICLE_CONDITION_RIGHT', 'D')}
          </Grid>
          <Grid item xs={5}>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex' }}>
          <Grid item xs={3}>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex'}}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500, width: '50%' }} className='mt-2 mb-2'>
              {this.translatorService.Tranlate('VEHICLE_FOR_REPLACING_TITLE', 'De inlocuit')}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500, width: '50%' }} className='mt-2 mb-2'>
              {this.translatorService.Tranlate('VEHICLE_FOR_REPAIR_TITLE', 'De reparat')}
            </div>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex'}}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500, width: '50%' }} className='mt-2 mb-2'>
              {this.translatorService.Tranlate('VEHICLE_FOR_REPLACING_TITLE', 'De inlocuit')}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500, width: '50%' }} className='mt-2 mb-2'>
              {this.translatorService.Tranlate('VEHICLE_FOR_REPAIR_TITLE', 'De reparat')}
            </div>
          </Grid>
          <Grid item xs={3}>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex'}}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500, width: '50%' }} className='mt-2 mb-2'>
              {this.translatorService.Tranlate('VEHICLE_FOR_REPLACING_TITLE', 'De inlocuit')}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 500, width: '50%' }} className='mt-2 mb-2'>
              {this.translatorService.Tranlate('VEHICLE_FOR_REPAIR_TITLE', 'De reparat')}
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICE_FRONT_SIDE_WINDOW', 'Geam lateral fata')}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.frontLeftSideWindowToReplace}
                onChange={(e) => this.handleToggleIsChecked('frontLeftSideWindowToReplace', 'frontLeftSideWindowToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.frontLeftSideWindowToRepair}
                onChange={(e) => this.handleToggleIsChecked('frontLeftSideWindowToRepair', 'frontLeftSideWindowToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>

          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.frontRightSideWindowToReplace}
                onChange={(e) => this.handleToggleIsChecked('frontRightSideWindowToReplace', 'frontRightSideWindowToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.frontRightSideWindowToRepair}
                onChange={(e) => this.handleToggleIsChecked('frontRightSideWindowToRepair', 'frontRightSideWindowToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICLE_REAR_WINDOW', 'Luneta')}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rearWindshieldToReplace}
                onChange={(e) => this.handleToggleIsChecked('rearWindshieldToReplace', 'rearWindshieldToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rearWindshieldToRepair}
                onChange={(e) => this.handleToggleIsChecked('rearWindshieldToRepair', 'rearWindshieldToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICE_REAR_SIDE_WINDOW', 'Geam lateral spate')}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rearLeftSideWindowToReplace}
                onChange={(e) => this.handleToggleIsChecked('rearLeftSideWindowToReplace', 'rearLeftSideWindowToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rearLeftSideWindowToRepair}
                onChange={(e) => this.handleToggleIsChecked('rearLeftSideWindowToRepair', 'rearLeftSideWindowToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>

          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rearRightSideWindowToReplace}
                onChange={(e) => this.handleToggleIsChecked('rearRightSideWindowToReplace', 'rearRightSideWindowToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rearRightSideWindowToRepair}
                onChange={(e) => this.handleToggleIsChecked('rearRightSideWindowToRepair', 'rearRightSideWindowToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICLE_WINDSCREEN', 'Parbriz')}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.windshieldToReplace}
                onChange={(e) => this.handleToggleIsChecked('windshieldToReplace', 'windshieldToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.windshieldToRepair}
                onChange={(e) => this.handleToggleIsChecked('windshieldToRepair', 'windshieldToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICE_SIDE_MIRROR', 'Oglinda laterala')}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.leftSideMirrorToReplace}
                onChange={(e) => this.handleToggleIsChecked('leftSideMirrorToReplace', 'leftSideMirrorToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.leftSideMirrorToRepair}
                onChange={(e) => this.handleToggleIsChecked('leftSideMirrorToRepair','leftSideMirrorToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>

          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rightSideMirrorToReplace}
                onChange={(e) => this.handleToggleIsChecked('rightSideMirrorToReplace', 'rightSideMirrorToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rightSideMirrorToRepair}
                onChange={(e) => this.handleToggleIsChecked('rightSideMirrorToRepair', 'rightSideMirrorToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICLE_HOOD_GRILL', 'Grila capota')}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.hoodGrilleToReplace}
                onChange={(e) => this.handleToggleIsChecked('hoodGrilleToReplace', 'hoodGrilleToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.hoodGrilleToRepair}
                onChange={(e) => this.handleToggleIsChecked('hoodGrilleToRepair', 'hoodGrilleToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICE_WIPERS', 'Stergatoare')}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.leftWiperToReplace}
                onChange={(e) => this.handleToggleIsChecked('leftWiperToReplace', 'leftWiperToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.leftWiperToRepair}
                onChange={(e) => this.handleToggleIsChecked('leftWiperToRepair', 'leftWiperToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>

          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rightWiperToReplace}
                onChange={(e) => this.handleToggleIsChecked('rightWiperToReplace', 'rightWiperToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rightWiperToRepair}
                onChange={(e) => this.handleToggleIsChecked('rightWiperToRepair', 'rightWiperToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICE_PROJECTOR', 'Proiector')}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.leftProjectorToReplace}
                onChange={(e) => this.handleToggleIsChecked('leftProjectorToReplace', 'leftProjectorToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.leftProjectorToRepair}
                onChange={(e) => this.handleToggleIsChecked('leftProjectorToRepair', 'leftProjectorToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>

          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rightProjectorToReplace}
                onChange={(e) => this.handleToggleIsChecked('rightProjectorToReplace', 'rightProjectorToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rightProjectorToRepair}
                onChange={(e) => this.handleToggleIsChecked('rightProjectorToRepair', 'rightProjectorToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICE_SIGNALIZATION', 'Semnalizare')}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.leftTurnSignalToReplace}
                onChange={(e) => this.handleToggleIsChecked('leftTurnSignalToReplace', 'leftTurnSignalToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.leftTurnSignalToRepair}
                onChange={(e) => this.handleToggleIsChecked('leftTurnSignalToRepair', 'leftTurnSignalToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>

          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rightTurnSignalToReplace}
                onChange={(e) => this.handleToggleIsChecked('rightTurnSignalToReplace', 'rightTurnSignalToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rightTurnSignalToRepair}
                onChange={(e) => this.handleToggleIsChecked('rightTurnSignalToRepair', 'rightTurnSignalToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICE_LIGHT_HOUSE', 'Far')}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.leftHeadlightToReplace}
                onChange={(e) => this.handleToggleIsChecked('leftHeadlightToReplace', 'leftHeadlightToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.leftHeadlightToRepair}
                onChange={(e) => this.handleToggleIsChecked('leftHeadlightToRepair', 'leftHeadlightToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>

          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rightHeadlightToReplace}
                onChange={(e) => this.handleToggleIsChecked('rightHeadlightToReplace', 'rightHeadlightToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rightHeadlightToRepair}
                onChange={(e) => this.handleToggleIsChecked('rightHeadlightToRepair', 'rightHeadlightToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            {this.translatorService.Tranlate('VEHICLE_CONDITION_LEFT_INFRASTRACTURE', 'S=stanga')}
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex'}}>
          <Grid item xs={3} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
            {this.translatorService.Tranlate('VEHICE_TRAFFIC_STOPS', 'Stopuri')}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.leftBrakeLightsToReplace}
                onChange={(e) => this.handleToggleIsChecked('leftBrakeLightsToReplace', 'leftBrakeLightsToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.leftBrakeLightsToRepair}
                onChange={(e) => this.handleToggleIsChecked('leftBrakeLightsToRepair', 'leftBrakeLightsToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>

          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rightBrakeLightsToReplace}
                onChange={(e) => this.handleToggleIsChecked('rightBrakeLightsToReplace', 'rightBrakeLightsToRepair')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                checked={this.props.conditionInfo.rightBrakeLightsToRepair}
                onChange={(e) => this.handleToggleIsChecked('rightBrakeLightsToRepair', 'rightBrakeLightsToReplace')(e.target.checked)}
                disabled={this.state.isDisabled}
                className="checkbox-no-padding"
              />
            </div>
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className="ml-2 mb-2">
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            {this.translatorService.Tranlate('VEHICLE_CONDITION_RIGHT_INFRASTRACTURE', 'R=dreapta')}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderMecanicsSection = () => {
    return (
      <>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography component="div" variant="h4" align="left" className="mt-3 bg-first mb-2"  style={{ color: 'white', padding: '3px' }}>
              {this.translatorService.Tranlate('VEHICLE_MECANICS_TITLE', 'Mecanica')}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex'}}>
            <Grid item xs={4}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="motor"
                name="motor"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_MOTOR', 'Motor')}
                value={this.props.conditionInfo.motor || ''}
                onChange={(e: any) => this.onChange('motor')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_MOTOR', 'Motor')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="leftCvJoints"
                name="leftCvJoints"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_CV_JOINTS_LEFT', 'Planetare S')}
                value={this.props.conditionInfo.leftCvJoints || ''}
                onChange={(e: any) => this.onChange('leftCvJoints')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_CV_JOINTS_LEFT', 'Planetare S')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="rightCvJoints"
                name="rightCvJoints"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_CV_JOINTS_RIGHT', 'Planetare D')}
                value={this.props.conditionInfo.rightCvJoints || ''}
                onChange={(e: any) => this.onChange('rightCvJoints')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_CV_JOINTS_RIGHT', 'Planetare D')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ display: 'flex'}}>
            <Grid xs={4}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="clutch"
                name="clutch"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_CLUTCH', 'Ambreaj')}
                value={this.props.conditionInfo.clutch || ''}
                onChange={(e: any) => this.onChange('clutch')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_CLUTCH', 'Ambreaj')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
            <Grid xs={4}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="leftShockAbsorbers"
                name="leftShockAbsorbers"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_SHOCK_ABSORBERS_LEFT', 'Amortizoare S')}
                value={this.props.conditionInfo.leftShockAbsorbers || ''}
                onChange={(e: any) => this.onChange('leftShockAbsorbers')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_SHOCK_ABSORBERS_LEFT', 'Amortizoare S')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
            <Grid xs={4}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="rightShockAbsorbers"
                name="rightShockAbsorbers"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_SHOCK_ABSORBERS_RIGHT', 'Amortizoare D')}
                value={this.props.conditionInfo.rightShockAbsorbers || ''}
                onChange={(e: any) => this.onChange('rightShockAbsorbers')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_SHOCK_ABSORBERS_RIGHT', 'Amortizoare D')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
          </Grid>

          
          <Grid item xs={12} style={{ display: 'flex'}}>
            <Grid xs={4}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="gearbox"
                name="gearbox"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_GEARBOX', 'Cutie viteze')}
                value={this.props.conditionInfo.gearbox || ''}
                onChange={(e: any) => this.onChange('gearbox')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_GEARBOX', 'Cutie viteze')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
            <Grid xs={4}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="frontBrakes"
                name="frontBrakes"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_FRONT_BRAKES', 'Frane F')}
                value={this.props.conditionInfo.frontBrakes || ''}
                onChange={(e: any) => this.onChange('frontBrakes')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_FRONT_BRAKES', 'Frane F')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
            <Grid xs={4}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="backBrakes"
                name="backBrakes"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_BACK_BRAKES', 'Frane SP')}
                value={this.props.conditionInfo.backBrakes || ''}
                onChange={(e: any) => this.onChange('backBrakes')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_BACK_BRAKES', 'Frane SP')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex'}}>
            <Grid xs={4}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="steering"
                name="steering"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_STEERING', 'Directie')}
                value={this.props.conditionInfo.steering || ''}
                onChange={(e: any) => this.onChange('steering')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_STEERING', 'Directie')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
            <Grid xs={4}>
            </Grid>
            <Grid xs={4}>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex'}}>
            <Grid xs={4}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="battery"
                name="battery"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_BATTERY', 'Baterie')}
                value={this.props.conditionInfo.battery || ''}
                onChange={(e: any) => this.onChange('battery')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_BATTERY', 'Baterie')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
            <Grid xs={4}>
            </Grid>
            <Grid xs={4}>
              {this.translatorService.Tranlate('MECANICS_EXPLICATION_LABEL', 'S=stanga , D=Dreapta , F=fata , SP=spate')}
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ display: 'flex'}}>
            <Grid xs={4}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="exhaust"
                name="exhaust"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_EXHAUST', 'Esapament')}
                value={this.props.conditionInfo.exhaust || ''}
                onChange={(e: any) => this.onChange('exhaust')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_EXHAUST', 'Esapament')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
            <Grid xs={4}>
            </Grid>
            <Grid xs={4}>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ display: 'flex'}}>
            <Grid xs={4}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="tires"
                name="tires"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_TIRES', 'Anvelope')}
                value={this.props.conditionInfo.tires || ''}
                onChange={(e: any) => this.onChange('tires')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_TIRES', 'Anvelope')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
            <Grid xs={4}>
            </Grid>
            <Grid xs={4}>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ display: 'flex'}}>
            <Grid xs={4}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="driveShaft"
                name="driveShaft"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_DRIVE_SAFT', 'Ax cardanic')}
                value={this.props.conditionInfo.driveShaft || ''}
                onChange={(e: any) => this.onChange('driveShaft')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_DRIVE_SAFT', 'Ax cardanic')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
            <Grid xs={4}>
            </Grid>
            <Grid xs={4}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  fullWidth
                  className="mt-1 ml-2 pr-2"
                  openTo="date"
                  views={['year', 'month', 'date']}
                  variant="inline"
                  disabled={this.state.isDisabled}
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  id="itpValidateDate"
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ITP_VALIDATE_DATE',
                    'Certificat ITP valabil pana la'
                  )}
                  value={this.props.conditionInfo.itpValidateDate || null}
                  onChange={this.handleITPValidateDateChange}
                  autoOk={true}
                  minDate={new Date()}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>
      
      </>
    )
  }

  handleITPValidateDateChange = (
    date: MaterialUiPickersDate | null,
    value?: string | null
  ) => {
    if (date === null || !date.isValid) {
      return;
    }
    const selectedDate = date ? date.format('YYYY-MM-DD') : undefined;

    this.props.setMavoVehicleObject({ 
      ...this.props.mavoVehicleState.mavoVehicle, 
      vehicleConditionInfo: {
        ...this.props.mavoVehicleState.mavoVehicle.vehicleConditionInfo, 
        itpValidateDate: selectedDate || null
      }
    })
  };

  renderInteriorAndAccesoriesSection = () => {
    return (
      <>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography component="div" variant="h4" align="left" className="mt-3 bg-first mb-2"  style={{ color: 'white', padding: '3px' }}>
              {this.translatorService.Tranlate('VEHICLE_INTERIOR_AND_ACCESORIES_TITLE', 'Interior si accesorii')}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex'}}>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="leftSeatUpholstery"
                name="leftSeatUpholstery"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_LEFT_SEAT_UPHOLSTERY', 'Tapiserie scaune stanga')}
                value={this.props.conditionInfo.leftSeatUpholstery || ''}
                onChange={(e: any) => this.onChange('leftSeatUpholstery')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_LEFT_SEAT_UPHOLSTERY', 'Tapiserie scaune stanga')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>

            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="interiorMirror"
                name="interiorMirror"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_INTERIOR_MIRROR', 'Oglinda interioara')}
                value={this.props.conditionInfo.interiorMirror || ''}
                onChange={(e: any) => this.onChange('interiorMirror')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_INTERIOR_MIRROR', 'Oglinda interioara')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ display: 'flex'}}>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="rightSeatUpholstery"
                name="rightSeatUpholstery"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_RIGHT_SEAT_UPHOLSTERY', 'Tapiserie scaune dreapta')}
                value={this.props.conditionInfo.rightSeatUpholstery || ''}
                onChange={(e: any) => this.onChange('rightSeatUpholstery')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_RIGHT_SEAT_UPHOLSTERY', 'Tapiserie scaune dreapta')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>

            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="firstAidKit"
                name="firstAidKit"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_FIRST_AID_KIT', 'Trusa sanitara')}
                value={this.props.conditionInfo.firstAidKit || ''}
                onChange={(e: any) => this.onChange('firstAidKit')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_FIRST_AID_KIT', 'Trusa sanitara')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ display: 'flex'}}>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="rearSeatUpholstery"
                name="rearSeatUpholstery"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_REAR_SEAT_UPHOLSTERY', 'Tapiserie scaune spate')}
                value={this.props.conditionInfo.rearSeatUpholstery || ''}
                onChange={(e: any) => this.onChange('rearSeatUpholstery')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_REAR_SEAT_UPHOLSTERY', 'Tapiserie scaune spate')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>

            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="bulbKit"
                name="bulbKit"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_BULB_KIT', 'Trusa de becuri')}
                value={this.props.conditionInfo.bulbKit || ''}
                onChange={(e: any) => this.onChange('bulbKit')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_BULB_KIT', 'Trusa de becuri')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ display: 'flex'}}>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="leftFrontHeadrest"
                name="leftFrontHeadrest"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_LEFT_FRONT_HEADREST', 'Tetiera fata stanga')}
                value={this.props.conditionInfo.leftFrontHeadrest || ''}
                onChange={(e: any) => this.onChange('leftFrontHeadrest')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_LEFT_FRONT_HEADREST', 'Tetiera fata stanga')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid> 

            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
              <Grid item xs={4} style={{ textAlign: 'left' }} className='ml-2'>
                {this.translatorService.Tranlate('VEHICLE_SPARE_TIRE', 'Roata de rezerva')}
              </Grid>
              <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.props.conditionInfo.spareTireGood}
                      onChange={(e) => this.handleToggleIsChecked('spareTireGood', 'spareTireReplace')(e.target.checked)}
                      disabled={this.state.isDisabled}
                      className="checkbox-no-top-padding"
                    />
                  }
                  label={this.translatorService.Tranlate('VEHICLE_SPARE_TIRE_GOOD', 'Buna')}
                  className="ml-0 mr-3 mb-0"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.props.conditionInfo.spareTireReplace}
                      onChange={(e) => this.handleToggleIsChecked('spareTireReplace', 'spareTireGood')(e.target.checked)}
                      disabled={this.state.isDisabled}
                      className="checkbox-no-top-padding"
                    />
                  }
                  label={this.translatorService.Tranlate('VEHICLE_SPARE_TIRE_REPLACE', 'De inlocuit')}
                  className="ml-0 mr-3 mb-0"
                />
              </Grid>
            </Grid>

          </Grid>

          <Grid item xs={12} style={{ display: 'flex'}}>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="rightFrontHeadrest"
                name="rightFrontHeadrest"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_RIGHT_FRONT_HEADREST', 'Tetiera fata dreapta')}
                value={this.props.conditionInfo.rightFrontHeadrest || ''}
                onChange={(e: any) => this.onChange('rightFrontHeadrest')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_RIGHT_FRONT_HEADREST', 'Tetiera fata dreapta')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
            <Grid item xs={6}>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ display: 'flex'}}>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={this.state.isDisabled}
                id="rearHeadrest"
                name="rearHeadrest"
                className="ml-2 mr-2 mt-1 pr-3 mt-0"
                placeholder={this.translatorService.Tranlate('VEHICLE_REAR_HEADREST', 'Tetiera spate')}
                value={this.props.conditionInfo.rearHeadrest || ''}
                onChange={(e: any) => this.onChange('rearHeadrest')(e)}
                label= {this.translatorService.Tranlate('VEHICLE_REAR_HEADREST', 'Tetiera spate')}
                validators={['maxLength']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'VALIDATORS_MAX_LENGTH',
                    'Numar maxim de caractere: '
                  ) + maxNumberOfCharacters
                ]}
              />
            </Grid>
            <Grid item xs={6}>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={2} style={{ display: 'flex', alignItems: 'flex-end'}} className="mt-2">
            <Checkbox
              checked={this.props.conditionInfo.frontFloorMats}
              onChange={(e) => this.onCheckBoxChange('frontFloorMats')(e.target.checked)}
              disabled={this.state.isDisabled}
              className='checkbox-no-top-padding'
            >
            </Checkbox>
            <div 
              onClick={() => !this.state.isDisabled && this.onCheckBoxChange('frontFloorMats')(!this.props.conditionInfo.frontFloorMats)} 
              style={{
                cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                userSelect: 'none'
              }}
            >
              {this.translatorService.Tranlate('VEHICLE_FLOOR_MATS', 'Covorase fata')}
            </div>
          </Grid>

          <Grid item xs={2} style={{ display: 'flex', alignItems: 'flex-end'}}  className="mt-2">
            <Checkbox
              checked={this.props.conditionInfo.fireExtinguisher}
              onChange={(e) => this.onCheckBoxChange('fireExtinguisher')(e.target.checked)}
              disabled={this.state.isDisabled}
              className='checkbox-no-top-padding'
            >
            </Checkbox>
            <div 
              onClick={() => !this.state.isDisabled && this.onCheckBoxChange('fireExtinguisher')(!this.props.conditionInfo.fireExtinguisher)} 
              style={{
                cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                userSelect: 'none'
              }}
            >
              {this.translatorService.Tranlate('VEHICLE_FIRE_EXTINGUISHER', 'Extinctor')}
            </div>
          </Grid>

          <Grid item xs={2} style={{ display: 'flex', alignItems: 'flex-end'}}  className="mt-2">
            <Checkbox
              checked={this.props.conditionInfo.wheelWrench}
              onChange={(e) => this.onCheckBoxChange('wheelWrench')(e.target.checked)}
              disabled={this.state.isDisabled}
              className='checkbox-no-top-padding'
            >
            </Checkbox>
            <div 
              onClick={() => !this.state.isDisabled && this.onCheckBoxChange('wheelWrench')(!this.props.conditionInfo.wheelWrench)} 
              style={{
                cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                userSelect: 'none'
              }}
            >
              {this.translatorService.Tranlate('VEHICLE_WHEEL_WRENCH', 'Cheie roti')}
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={2} style={{ display: 'flex', alignItems: 'flex-end'}} className="mt-2">
            <Checkbox
              checked={this.props.conditionInfo.rearFloorMats}
              onChange={(e) => this.onCheckBoxChange('rearFloorMats')(e.target.checked)}
              disabled={this.state.isDisabled}
              className='checkbox-no-top-padding'
            ></Checkbox>
            <div 
              onClick={() => !this.state.isDisabled && this.onCheckBoxChange('rearFloorMats')(!this.props.conditionInfo.rearFloorMats)} 
              style={{
                cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                userSelect: 'none'
              }}
            >
              {this.translatorService.Tranlate('VEHICLE_REAR_FLOOR_MATS', 'Covorase spate')}
            </div>
          </Grid>

          <Grid item xs={2} style={{ display: 'flex', alignItems: 'flex-end'}}  className="mt-2">
            <Checkbox
              checked={this.props.conditionInfo.reflectiveTriangle}
              onChange={(e) => this.onCheckBoxChange('reflectiveTriangle')(e.target.checked)}
              disabled={this.state.isDisabled}
              className='checkbox-no-top-padding'
            >
            </Checkbox>
            <div 
              onClick={() => !this.state.isDisabled && this.onCheckBoxChange('reflectiveTriangle')(!this.props.conditionInfo.reflectiveTriangle)} 
              style={{
                cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                userSelect: 'none'
              }}
            >
              {this.translatorService.Tranlate('VEHICLE_REFLECTIVE_TRIANGLE', 'Triunghi reflectorizant')}
            </div>
          </Grid>

          <Grid item xs={2} style={{ display: 'flex', alignItems: 'flex-end'}}  className="mt-2">
            <Checkbox
              checked={this.props.conditionInfo.carJack}
              onChange={(e) => this.onCheckBoxChange('carJack')(e.target.checked)}
              disabled={this.state.isDisabled}
              className='checkbox-no-top-padding'
            >
            </Checkbox>
            <div 
              onClick={() => !this.state.isDisabled && this.onCheckBoxChange('carJack')(!this.props.conditionInfo.carJack)} 
              style={{
                cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                userSelect: 'none'
              }}
            >
              {this.translatorService.Tranlate('VEHICLE_CAR_JACK', 'Cric')}
            </div>
          </Grid>
        </Grid>
      </>
    )
  };

  renderObservationsSection = () => {
    return (
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Typography component="div" variant="h4" align="left" className="mt-3 bg-first mb-2 mr-2"  style={{ color: 'white', padding: '3px' }}>
            {this.translatorService.Tranlate('VEHICLE_OBSERVATIONS_TITLE', 'Observatii')}
          </Typography>
            <TextValidator
              fullWidth
              disabled={this.isDisabled()}
              id="observations"
              className="m-2 pr-3"
              name="observations"
              placeholder={this.translatorService.Tranlate(
                'VEHICLE_OBSERVATIONS',
                'Observatii'
              )}
              value={this.props.conditionInfo.observations || ''}
              onChange={(e: any) => this.onChange('observations')(e)}
              label={this.translatorService.Tranlate(
                'VEHICLE_OBSERVATIONS',
                'Observatii'
              )}
              validators={['maxLength']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_MAX_LENGTH',
                  'Numar maxim de caractere: '
                ) + maxNumberOfCharacters
              ]}
            />
        </Grid>

        <Grid item xs={6}>
          <Typography component="div" variant="h4" align="left" className="mt-3 bg-first mb-2"  style={{ color: 'white', padding: '3px' }}>
            {this.translatorService.Tranlate('VEHICLE_ESTIMATE_COST_REPAIR_TITLE', 'Deviz estimativ cost remedieri (€)')}
          </Typography>

          <TextValidator
              fullWidth
              id="repairCostPrice"
              className="m-2 pr-3"
              name="repairCostPrice"
              placeholder={this.translatorService.Tranlate('VEHICLE_ESTIMATE_COST_REPAIR', 'Deviz estimativ cost remedieri (€)')}
              value={this.props.conditionInfo.repairCostPrice || ''}
              onChange={(e: any) =>
                this.onRepairPriceChange(e)
              }
              label={this.translatorService.Tranlate('VEHICLE_ESTIMATE_COST_REPAIR', 'Deviz estimativ cost remedieri (€)')}
              validators={['isGreaterThanMaxValue']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'GRATTER_THAN_MAX_VALUE_ERROR_MESSAGE',
                  'Pretul introdus este prea mare. Trebuie sa fie mica decat 999999.99'
                ) 
              ]}
              InputProps={{
                inputComponent: this.NumberDecimalFormatCustom
              }}
            />
        </Grid>
      </Grid>
    );
  }

  NumberDecimalFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };
 
  renderVehicleStatusForm = () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
          // this.submitCaseVehicleDetailsData(e);
        }}
      >
        <div className="text-center">
          {this.renderBodySection()}
          {this.renderMecanicsSection()}
          {this.renderInteriorAndAccesoriesSection()}
          {this.renderObservationsSection()}
        </div>
      </ValidatorForm>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center">{this.renderVehicleStatusForm()}</div>
        ) : null}
      </Fragment>
    );
  }
}

export default connect(
  (
    state: ApplicationState,
    ownProps: MavoVehicleStatusDetailsProps
  ) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle,
    mavoVehicle: state.mavoVehicle,
    ...ownProps
  }),
  { ...CaseSettingsActionCreators, ...VehicleActionCreators, ...MavoVehicleActionCreators }
)(withSnackbar(MavoStatusDataContainer as any));
