import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import {
  Box,
  Button,
  Grid,
  CardContent,
  Card
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import { RouteComponentProps } from 'react-router';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import NumberFormat from 'react-number-format';
import { isNullOrUndefined } from 'util';
import { DropzoneArea } from 'material-ui-dropzone';
import { SellerData } from '../../../interfaces/Organization';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { IReferential } from '../../../interfaces/IReferential';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import Referentials from '../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../helpers/Constants';
import { Autocomplete } from '@material-ui/lab';

interface ISellerDataListState {
  sellerData: SellerData;
  sellerDataList: SellerData[];
  isLoading: boolean;
  isDialogLoading: boolean;
  isDialogOpen: boolean;
  countryList: IReferential[];
  regionList: IReferential[];
  cityList: IReferential[];
}

type ISellerDataListProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class SellerDataList extends React.PureComponent<
  ISellerDataListProps,
  ISellerDataListState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private appReferentialService!: IReferentialService;
  static contextType = ApplicationContext;
  private sellerData = {
    id: 0,
    name: '',
    country: null,
    countryId: null,
    region: null,
    regionId: null,
    city: null,
    cityId: null,
    address: '',
    address2: '',
    address3: '',
    companyNumber: '',
    registryNumber: '',
    bank: '',
    bankAccount: '',
    postalCode: ''
  } as SellerData;

  state = {
    sellerData: {
      id: 0,
      name: '',
      country: null,
      countryId: null,
      region: null,
      regionId: null,
      city: null,
      cityId: null,
      address: '',
      address2: '',
      address3: '',
      companyNumber: '',
      registryNumber: '',
      bank: '',
      bankAccount: '',
      postalCode: ''
    } as SellerData,
    sellerDataList: [],
    isLoading: false,
    selectedFilter: 0,
    isDialogOpen: false,
    isDialogLoading: false,
    countryList: [],
    regionList: [],
    cityList: []
  } as ISellerDataListState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadSellerDataList();
      }
    );
  }

  loadSellerDataList = async () => {
    const countryRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.Country
    );
    const regionRef = Referentials.referential.find((item) => item.code === ReferentialCode.Region);
    const cityRef = Referentials.referential.find((item) => item.code === ReferentialCode.City);

    const [sellerDataList, countryList, regionList, cityList] = await Promise.all([
      this.organizationService.GetSellerDataList(),
      this.appReferentialService.Get(countryRef!.baseUrl),
      this.appReferentialService.Get(regionRef!.baseUrl),
      this.appReferentialService.Get(cityRef!.baseUrl)
    ]);

    const sellerData = sellerDataList.length > 0 ? sellerDataList[0] : this.sellerData;
    const image = sellerData.id === 0 ? null : await this.organizationService.GetSellerLogo(sellerData.id);

    this.setState({
      sellerDataList: sellerDataList,
      sellerData: image == null ? sellerData : { ...sellerData, image: new File([image], 'logo')},
      isLoading: false,
      countryList: countryList,
      regionList: regionList,
      cityList: cityList
    });
  };


  public saveSellerData = async () => {
    let sellerId = this.state.sellerData.id;
    try {
      if (this.state.sellerData.id === 0) {
        const sellerData = await this.organizationService.AddSellerData(this.state.sellerData);
        sellerId = sellerData.id;
      } else {
        await this.organizationService.UpdateSellerData(this.state.sellerData);
      }

      await this.saveImage(sellerId, this.state.sellerData.image);

      await this.loadSellerDataList();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };
  
  
  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };
  

  private handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: any;
    if (event.target.type === 'text') {
      value = event.currentTarget.value;
    }
    this.setState({
      sellerData: {
        ...this.state.sellerData,
        [event.target.id]: value
      }
    });
  };

  handleUploadAttachment = async (files: File[]) => {
    if (files.length === 0) {
      return;
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(files[0]);
    img.onload = async () => {
      this.setState({
        sellerData: {
          ...this.state.sellerData,
          image: files![0]
        }
      });  
      return;
    };
  };


  
  saveImage = async (sellerId: number, image: File) => {
    if (sellerId === 0) {
      return;
    }

    if (image === null) {
      return;
    }

    try {
      await this.organizationService.AddSellerLogo(sellerId, image);

    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

   private handleCountryChange =  (newValue: IReferential | null) => {
    const countryId = newValue !== null ? newValue!.id : null;
    this.setState({
      sellerData: {
        ...this.state.sellerData,
        country: newValue,
        countryId: countryId,
        region: null,
        regionId: null,
        city: null,
        cityId: null
      }
    });
  };
  
  private handleRegionChange = (newValue: IReferential | null) => {
    this.setState({
      sellerData: {
        ...this.state.sellerData,
        region: newValue,
        regionId: newValue !== null ? newValue!.id : null,
        city: null,
        cityId: null
      }
    });
  };
  
  private handleCityChange = (newValue: IReferential | null) => {
    this.setState({
      sellerData: {
        ...this.state.sellerData,
        city: newValue,
        cityId: newValue !== null ? newValue!.id : null
      }
    });
  };

  private getRegionsOptions(): IReferential[] {
    const filteredRegions = this.state.regionList.filter(
      (region) => (this.state.sellerData.countryId && region.dependencyId) && region.dependencyId === this.state.sellerData.countryId
    );

    return filteredRegions || [];
  }

  private getCitiesOptions(): IReferential[] {
    const filteredCities = this.state.cityList.filter(
      (city) => (this.state.sellerData.regionId && city.dependencyId) && city.dependencyId === this.state.sellerData.regionId
    );

    return filteredCities || [];
  }

  public renderAddForm() {
    return (
      <ValidatorForm onSubmit={this.saveSellerData} instantValidate={true}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="name"
              className="ml-2 mr-2 mt-1"
              id="name"
              value={this.state.sellerData.name || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate('EDIT_ORGANIZATION_NAME_LABEL', 'Nume')}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="companyNumber"
              className="ml-2 mr-2 mt-1"
              id="companyNumber"
              value={this.state.sellerData.companyNumber || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'SELLER_DATA_COMPANY_NUMBER_LABEL',
                'CUI/CIF/Cod TVA'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="registryNumber"
              className="ml-2 mr-2 mt-1"
              id="registryNumber"
              value={this.state.sellerData.registryNumber || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_REGISTRY_NUMBER_LABEL',
                'Nr. Reg. Comertului'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              id="country"
              className="ml-2 mr-2 mt-1"
              options={this.state.countryList}
              value={this.state.sellerData.country}
              onChange={(e: any, newValue: IReferential | null) =>
                this.handleCountryChange(newValue)
              }
              getOptionLabel={(option: IReferential) => option.displayName || ''}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="country"
                  value={this.state.sellerData.country || ''}
                  label={this.translatorService.Tranlate(
                    'SELLER_DATA_COUNTRY_LABEL',
                    'Tara'
                  )}
                  fullWidth
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <Autocomplete
              id="region"
              className="ml-2 mr-2 mt-1"
              options={this.getRegionsOptions()}
              value={this.state.sellerData.region}
              onChange={(e: any, newValue: IReferential | null) =>
                this.handleRegionChange(newValue)
              }
              getOptionLabel={(option: IReferential) => option.displayName || ''}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="region"
                  value={this.state.sellerData.region || ''}
                  label={this.translatorService.Tranlate(
                    'SELLER_DATA_COUNTY_LABEL',
                    'Judet'
                  )}
                  fullWidth
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              id="city"
              className="ml-2 mr-2 mt-1"
              options={this.getCitiesOptions()}
              value={this.state.sellerData.city}
              onChange={(e: any, newValue: IReferential | null) =>
                this.handleCityChange(newValue)
              }
              getOptionLabel={(option: IReferential) => option.displayName || ''}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="city"
                  value={this.state.sellerData.city || ''}
                  label={this.translatorService.Tranlate(
                    'SELLER_DATA_CITY_LABEL',
                    'Oras'
                  )}
                  fullWidth
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="address"
              className="ml-2 mr-2 mt-1"
              id="address"
              value={this.state.sellerData.address || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'SELLER_DATA_ADDRESS_1_LABEL',
                'Adresa 1'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
              />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="address2"
              className="ml-2 mr-2 mt-1"
              id="address2"
              value={this.state.sellerData.address2 || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'SELLER_DATA_ADDRESS_2_LABEL',
                'Adresa 2'
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="address3"
              className="ml-2 mr-2 mt-1"
              id="address3"
              value={this.state.sellerData.address3 || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'SELLER_DATA_ADDRESS_3_LABEL',
                'Adresa 3'
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="postalCode"
              className="ml-2 mr-2 mt-1"
              id="postalCode"
              value={this.state.sellerData.postalCode || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_POSTALCODE_LABEL',
                'Cod Postal'
              )}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="contactPerson"
              className="ml-2 mr-2 mt-1"
              id="contactPerson"
              value={this.state.sellerData.contactPerson || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_CONTACT_PERSON_LABEL',
                'Persoana Contact'
              )}
              validators={[]}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="phone"
              className="ml-2 mr-2 mt-1"
              id="phone"
              value={this.state.sellerData.phone || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_PHONE_LABEL',
                'Telefon'
              )}
              validators={[]}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              className="ml-2 mr-2 mt-1"
              name="ownCapital"
              placeholder={this.translatorService.Tranlate(
                'SELLER_DATA_OWN_CAPITAL',
                'Capital social'
              ) + ' (lei)'}
              value={this.state.sellerData.ownCapital}
              onChange={(e: any) => {
                this.setState({
                  sellerData: {
                    ...this.state.sellerData,
                    ownCapital: parseFloat(e.target.value)
                  } 
                });
              }}
              validators={['required', 'minNumber:1']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu'),
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
              label={this.translatorService.Tranlate(
                'SELLER_DATA_OWN_CAPITAL',
                'Capital social'
              ) + ' (lei)'}
              InputProps={{
                inputComponent: this.NumberFormatCustom
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="bank"
              className="ml-2 mr-2 mt-1"
              id="bank"
              value={this.state.sellerData.bank || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate('ORGANIZATION_BANK_ACCOUNTS_BANK_HEADER', 'Banca')}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="bankAccount"
              className="ml-2 mr-2 mt-1"
              id="bankAccount"
              value={this.state.sellerData.bankAccount || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'ORGANIZATION_BANK_ACCOUNTS_BANK_ACCOUNT_HEADER',
                'Bank Account'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          </Grid>
        </Grid>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center p-3">
          {isNullOrUndefined(this.state.sellerData.image) ? (
            ''
          ) : (
            <img src={URL.createObjectURL(this.state.sellerData.image)} alt="" className="img-fluid" />
          )}
        </div>
        <DropzoneArea
          dropzoneClass="custom-dropzone mb-3"
          dropzoneText={
            this.translatorService.Tranlate(
              'CASE_CAR_PHOTOS_FORM_UPLOAD_LOGO_IMAGE',
              'Incarca Logo'
            )
          }
          showPreviews={false}
          showPreviewsInDropzone={false}
          filesLimit={1}
          onChange={(files: File[]) => this.handleUploadAttachment(files)}
          previewText=""
          showAlerts={false}
          acceptedFiles={['image/*']}
        />
      <div className="text-center">
        <Button className="m-2" variant="contained" color="primary" type="submit">
          {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
        </Button>
      </div>
    </ValidatorForm>

    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    

    return (
      <Fragment>
        <Card>
          <CardContent>
            <Box mt={1} pt={1}>
              <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
              </div>
              {!this.state.isLoading ? this.renderAddForm() : null}
            </Box>
          </CardContent>
        </Card>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(SellerDataList as any));
