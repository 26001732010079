import * as React from 'react';
import { ApplicationState, AppState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import {
  Card,
  Grid,
  Tab,
  Tabs,
  AppBar,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  DialogTitle,
  DialogContent,
  Dialog,
  IconButton,
  Modal,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import TabPanel from '../_shared/Tabs';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import { IReferential } from '../../interfaces/IReferential';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  MavoVehicleAuction,
  Provenance,
  MavoAdminAuctionsList,
  MavoAdminAuctionsFilters,
  CloseAuctionRequestDto
} from '../../interfaces/Vehicle';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Loader from '../Loader';
import noop from 'lodash/noop';
import _ from 'lodash';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { MavoVehicleState } from '../../store/reducers/MavoVehicle';
import NumberFormat from 'react-number-format';
import { VehicleAuctionActionCreators } from '../../store/actions/VehicleAuction';
import CloseIcon from '@material-ui/icons/Close';
import MavoAuctionDetailsForm from './MavoAuctionDetailsForm';
import {MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import RealTimeDate from './RealTimeDate';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import FileSaver from 'file-saver';
import { ScaleLoader } from 'react-spinners';

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

type MavoVehiclesAdminAuctionsProps = {
  appState: AppState;
  mavoVehicleState: MavoVehicleState;
  mavoVehicleAuctionState: MavoVehicleAuction;
} &
  typeof MavoVehicleActionCreators & typeof VehicleAuctionActionCreators &
  ProviderContext &
  RouteComponentProps;

interface IMavoVehiclesAdminAuctionsState {
  auctionsList: MavoAdminAuctionsList;
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
  provenanceEntities: Provenance[];
  selectedProvenanceEntities: Provenance[];
  statusEntities: IReferential[];
  selectedStatusEntities: IReferential[];
  selectedTab: number;
  isLoading: boolean;
  isLoadingAuctions: boolean;
  count: number;
  page: number;
  pageSize: number;
  vin: string;
  selectedAuction: MavoVehicleAuction | null;
  executing: boolean;
  isDialogOpen: boolean;
  isOfferDialogOpen: boolean;
  isOpen: boolean;
  isLoadingExport: boolean;
}

class MavoVehiclesAdminAuctions extends React.PureComponent<MavoVehiclesAdminAuctionsProps, IMavoVehiclesAdminAuctionsState> {
  private cbContext!: AppContext;
  static contextType = ApplicationContext;

  state = {
    auctionsList: {} as MavoAdminAuctionsList,
    selectedStartDate: null,
    selectedEndDate: null,
    provenanceEntities: [],
    selectedProvenanceEntities: [],
    statusEntities: [],
    selectedStatusEntities: [],
    selectedTab: 0,
    isLoading: false,
    isLoadingAuctions: false,
    count: 1,
    page: 0,
    pageSize: 10,
    vin: '',
    selectedAuction: null,
    executing: false,
    isDialogOpen: false,
    isOfferDialogOpen: false,
    isOpen: false,
    isLoadingExport: false
  } as IMavoVehiclesAdminAuctionsState;

  public async componentDidMount() {
    const mavoAdminAuctionsFilters = {
      startDate: null,
      endDate: null,
      statusIds: [],
      provenanceIds: [],
      page: this.state.page + 1,
      pageSize: this.state.pageSize,
      vin: '',
    } as MavoAdminAuctionsFilters;

    this.setState({
      isLoading: true
    });

    const [auctionsList] = await Promise.all([
      this.cbContext.vehicleService.GetAdminAuctions(mavoAdminAuctionsFilters)
    ]);
    this.setState(
      {
        auctionsList: auctionsList,
        count: auctionsList.total,
        page: auctionsList.page - 1
      },
      async () => {
        await this.reloadFilters();
      }
    );
  }

  loadAuctions = async (page: number, rowsPerPage: number) => {
    this.setState({
      isLoadingAuctions: true,
      selectedAuction: null
    });

    const mavoAdminAuctionsFilters = {
      startDate: this.state.selectedStartDate
      ? new Date(this.state.selectedStartDate.setHours(0, 0, 0, 0))
      : null,
      endDate: this.state.selectedEndDate
      ? new Date(this.state.selectedEndDate.setHours(23, 59, 59, 999))
      : null,
      provenanceIds: this.state.selectedProvenanceEntities.map((item) => item.id),
      statusIds: this.state.selectedStatusEntities.map((item) => item.id),
      page: page + 1,
      pageSize: rowsPerPage,
      vin: this.state.vin
    } as MavoAdminAuctionsFilters;

    const auctionsList = await this.cbContext.vehicleService.GetAdminAuctions(mavoAdminAuctionsFilters);
    this.setState({
      auctionsList: auctionsList,
      page: auctionsList.page - 1,
      pageSize: rowsPerPage,
      count: auctionsList.total,
      isLoadingAuctions: false
    });
  };

  reloadFilters = async () => {
    const filters = await this.cbContext.vehicleService.GetAdminAuctionsFilters();
    this.setState({
      provenanceEntities: filters.provenanceList,
      statusEntities: filters.statusList,
      isLoading: false
    });
  };

  makeSelectedAuction =  (isSelected: boolean, id: number) => {
    const auctions = _.cloneDeep(this.state.auctionsList.auctions);
    const auction = auctions.find((item) => item.id === id);
    if (auction) {
      auction.isSelected = isSelected;
    }

    if (isSelected) {
      auctions.forEach((item) => {
        if (item.id !== id) {
          item.isSelected = false;
        }   
      });
    }
    this.setState({
      auctionsList: {
        ...this.state.auctionsList,
        auctions: auctions
      },
      selectedAuction: isSelected ? (auction ? auction : null): null
    });
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableCell: {
        head: {
          paddingLeft: "2px",
          paddingRight: "2px",
          paddingTop: "2px",
          paddingBottom: "2px"
        },
      },
      MUIDataTableBodyCell: {
        stackedCommon: {
          paddingLeft: "2px",
          paddingRight: "2px",
          paddingTop: "0px",
          paddingBottom: "0px",
          height: "40px !important"
        }
      },
      MuiCheckbox: {
        root: {
          padding: "0px", // Adjust the padding to make the checkbox smaller
        },
        colorSecondary: {
          '&.Mui-checked': {
            color: "#3d4977 !important",
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.03) !important'
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.03) !important'
          }
        }
      }
    } as any
  });
  
  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'id',
        label: ' ',
        options: {
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            const auction = this.state.auctionsList.auctions.find((item) => item.id === tableMeta.rowData[0]);
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    <FormControlLabel
                      className="m-2"
                      control={
                        <Checkbox
                          checked={auction!.isSelected || false}
                          onChange={(e) => {
                            this.makeSelectedAuction(e.target.checked, auction!.id);
                          }}
                        ></Checkbox>
                        }
                        label=''
                    />
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'mavoVehicle.vin',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_VIN_CODE', 'VIN'),
        
      },
      {
        name: 'mavoVehicle.make.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MAKE', 'Marca'),
        options: {
          sort: false
        }
      },
      {
        name: 'mavoVehicle.model.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MODEL', 'Model'),
        options: {
          sort: false
        }
      },      
      {
        name: '',
        label: this.cbContext.translatorService.Tranlate(
          'AUCTION_ADMIN_HAS_OFFERS',
          'Oferte'
        ),
        options: {
          sort: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            const auction = this.state.auctionsList.auctions.find((item) => item.id === tableMeta.rowData[0]);
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div>
                    {auction!.mavoVehicleAuctionOffers.length > 0 ? 
                        this.cbContext.translatorService.Tranlate("AUCTION_ADMIN_HAS_OFFERS_YES", "Da")
                       : this.cbContext.translatorService.Tranlate("AUCTION_ADMIN_HAS_OFFERS_NO", "Nu")
                    }
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'startPrice',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_AUCTION_START_PRICE', 'Pret start licitatie'),
        options: {
          sort: false
        }
      },
      {
        name: 'currentPrice',
        label: this.cbContext.translatorService.Tranlate(
          'AUCTION_ADMIN_CURRENT_PRICE',
          'Pret curent'
        ),
        options: {
          sort: false
        }
      },
      {
        name: 'startDate',
        label: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_AUCTION_START_DATE',
          'Data start'
        ),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    {value
                      ? moment
                          .utc(value)
                          .local()
                          .format(this.props.appState.dateFormat)
                      : null}
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'endDate',
        label: this.cbContext.translatorService.Tranlate(
          'ADMIN_AUCTION_END_DATE',
          'Data stop'
        ),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    {value
                      ? moment(value)
                        .format(this.props.appState.longDateFormat)
                      : null}
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'auctionStatus.displayName',
        label: this.cbContext.translatorService.Tranlate('ADMIN_AUCTION_STATUS', 'Stare licitatie'),
        options: {
          sort: false
        }
      },    
      {
        name: 'mavoVehicle.provenance.type',
        label: this.cbContext.translatorService.Tranlate(
          'MENU_NOMENCLATURES_TAB_PROVENANCE',
          'Provenienta'
        ),
        options: {
          sort: false
        }
      },
      {
        name: '',
        label: this.cbContext.translatorService.Tranlate('MAVO_AUCTION_REMAINING_TIME', 'Timp ramas'),
        options: {
          sort: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            const auction = this.state.auctionsList.auctions.find((item) => item.id === tableMeta.rowData[0]);
            return (
              <span>   
                <RealTimeDate 
                  endDate={auction!.endDate}
                  showLastOffer={false}
                  auctionStatus={auction!.auctionStatus}
                />
              </span>
            );
          }
        }
      }
    ];

    return columns;
  };

  sortFunction = (a: any, b: any) => {
    return a.order - b.order;
  };

  handleProvenanceEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedProvenanceEntities: newValue
      }
    );
  };

  renderAdminAuctionsButtons() {
    return (
      <div className="row m-0 mt-3">
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              if (!this.state.selectedAuction) {
                this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('PLEASE_SELECT_LINE', 'Va rugam sa selectati o linie!'), {
                  variant: 'warning'
                });
                return;
              }

              this.setState({
                isDialogOpen: true
              });
            }}
          >
            {this.cbContext.translatorService.Tranlate(
              'ADMIN_AUCTION_DETAILS',
              'Consultare'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => { 
              if (!this.state.selectedAuction) {
                this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('PLEASE_SELECT_LINE', 'Va rugam sa selectati o linie!'), {
                  variant: 'warning'
                });
                return;
              }
              this.handleOpenConfirmationDialog();
            }}
          >
            {this.cbContext.translatorService.Tranlate(
              'ADMIN_STOP_AUCTION',
              'Stopare'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={this.handleExportAuctionOffers}
          >
            {this.cbContext.translatorService.Tranlate(
              'ADMIN_AUCTION_EXPORT',
              'Export'
            )}
          </Button>
        </div>
      </div>

    );
  }

  renderSearchButtons() {
    return (
      <div className="row m-0 mt-3">
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => this.loadAuctions(this.state.page, this.state.pageSize)}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_SEARCH',
              'Cautare'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => this.resetFilters()}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_RESET',
              'Reset'
            )}
          </Button>
        </div>
      </div>

    );
  }

  handleStatusEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedStatusEntities: newValue
      }
    );
  };

  resetFilters = async () => {
    this.setState({
      selectedStartDate: null,
      selectedEndDate: null,
      selectedProvenanceEntities: [],
      selectedStatusEntities: [],
      vin: ''
    }, async () => {
      await this.loadAuctions(this.state.page, this.state.pageSize);
    });
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };
  
  handleStartDateChange = async (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }
    this.setState(
      {
        selectedStartDate: date!.toDate(),
        isLoading: false
      }
    );
  };

  handleEndDateChange = async (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }

    this.setState(
      {
        selectedEndDate: date!.toDate(),
        isLoading: false
      }
    );
  };


  renderFilters = () => {
    return (
      <div className="my-1">
        <ValidatorForm onSubmit={noop}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  className="m-0 mt-1"
                  disableToolbar
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  label={this.cbContext.translatorService.Tranlate('AUCTIONS_FILTER_START_DATE', 'Data start')}
                  value={this.state.selectedStartDate}
                  onChange={this.handleStartDateChange}
                  autoOk={true}
                  // minDate={activeButton === 1 ? new Date() : this.state.minDate}
                />
              </MuiPickersUtilsProvider> 
              
              <Autocomplete
                id="state"
                size="small"
                className="m-0 mt-1"
                multiple
                options={this.state.statusEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedStatusEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleStatusEntitiesChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="statusEntities"
                    value={this.state.selectedStatusEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'MAVO_AUCTION_STATE',
                      'Stare'
                    )}
                    fullWidth
                  />
                )}
              />

              <div className="mt-1">
                <TextValidator
                  fullWidth
                  id="vin"
                  name="color"
                  placeholder={this.cbContext.translatorService.Tranlate(
                    'MAVO_VEHICLES_VEHICLE_VIN_CODE',
                    'Vin'
                  )}
                  value={this.state.vin}
                  onChange={(e: any) => {
                    this.setState({
                      vin: e.target.value
                    });
                  }}
                  validators={['required']}
                  label={this.cbContext.translatorService.Tranlate('CALCULATION_DETAILS_VIN', 'Vin')}
                />
              </div>
               
            </Grid>

            <Grid item xs={6}>
              <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={LocalizedUtils}
                  locale={this.props.appState.language}
                >
                <DatePicker
                  style={{ width: '100%' }}
                  className="m-0 mt-1"
                  disableToolbar
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  label={this.cbContext.translatorService.Tranlate('AUCTIONS_FILTER_END_DATE', 'Data stop')}
                  value={this.state.selectedEndDate}
                  onChange={this.handleEndDateChange}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>

              <Autocomplete
                id="provenance"
                size="small"
                className="m-0 mt-1"
                multiple
                options={this.state.provenanceEntities.sort(function (a, b) {
                  return a.type.localeCompare(b.type);
                })}
                value={this.state.selectedProvenanceEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleProvenanceEntitiesChange(newValue)
                }
                getOptionLabel={(option: Provenance) => option.type || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="provenanceEntities"
                    value={this.state.selectedProvenanceEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'MENU_NOMENCLATURES_TAB_PROVENANCE',
                      'Provenienta'
                    )}
                    fullWidth
                  />
                )}
              />

            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    );
  };

  onRowDoubleClick = (
    dataIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => {
    if (columnIndex != 2) {
      return;
    }
    const auction = this.state.auctionsList.auctions[dataIndex];
    this.setState({
      isDialogOpen: true,
      selectedAuction: auction!
    });
  };

  tableOptions = () => {
    return {
      sort: true,
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.cbContext.translatorService),
      elevation: 0,
      serverSide: true,
      count: this.state.count,
      rowsPerPage: this.state.pageSize,
      page: this.state.page,
      onCellClick: (colData: any, cellMeta: any) => {
        if (cellMeta && cellMeta.colIndex !== null && cellMeta.rowIndex !== null) {
          // Set up a double-click event listener on the cell
          if (cellMeta.event.detail === 2) {
            this.onRowDoubleClick(cellMeta.dataIndex, cellMeta.rowIndex, cellMeta.colIndex);
          }
        }
      },
      onTableChange: async (action: any, tableState: any) => {
        switch (action) {
          case 'changePage':
            await this.loadAuctions(tableState.page, tableState.rowsPerPage);
            break;
          case 'changeRowsPerPage':
            await this.loadAuctions(tableState.page, tableState.rowsPerPage);
            break;
          case 'sort':
            break;
          default:
        }
      }
    };
  };
  
  closeDialog = async () => {
    this.setState(
      {
        isDialogOpen: false,
        selectedAuction: null
      }, async () => {
        this.loadAuctions(this.state.page, this.state.pageSize);
      }
    );
  };

  handleOpenConfirmationDialog = () => {
    this.setState({
      isOpen: true
    });
  };

  handleCloseModal =  () => {
    this.setState({
      isOpen: false
    }, () => {
      this.makeSelectedAuction(false, this.state.selectedAuction!.id)
    });
  };

  closeAuction = async () => {
    try {
      const request = {
        auctionId: this.state.selectedAuction!.id,
      } as CloseAuctionRequestDto;

      await this.cbContext.vehicleService.CloseAuction(request);

      this.setState({
        isOpen: false,
        selectedAuction: null
      }, async () => {
        this.loadAuctions(this.state.page, this.state.pageSize);
      });

      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  handleExportAuctionOffers = async () => {
    try {
      this.setState({
        isLoadingExport: true
      });

      const mavoAdminAuctionsFilters = {
        startDate: this.state.selectedStartDate
        ? new Date(this.state.selectedStartDate.setHours(0, 0, 0, 0))
        : null,
        endDate: this.state.selectedEndDate
        ? new Date(this.state.selectedEndDate.setHours(23, 59, 59, 999))
        : null,
        provenanceIds: this.state.selectedProvenanceEntities.map((item) => item.id),
        statusIds: this.state.selectedStatusEntities.map((item) => item.id),
        vin: this.state.vin
      } as MavoAdminAuctionsFilters;
  
      const fileBlob = await this.cbContext.vehicleService.ExportAuctionOffers(mavoAdminAuctionsFilters);

      FileSaver.saveAs(fileBlob, 'Export_Licitatii_Detaliu' + '.xlsx');

      this.setState({
        isLoadingExport: false
      });

      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (err: any) {
      this.setState({
        isLoadingExport: false
      });
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }



  public render() {
    this.cbContext = this.context as AppContext;

    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <Card>
          <AppBar position="static" elevation={0} color="secondary">
            <Tabs
              TabIndicatorProps={{
                style: {
                  height: '4px'
                }
              }}
              value={this.state.selectedTab}
              variant="fullWidth"
              centered={true}
              indicatorColor="primary"
              aria-label="simple tabs example"
            >
              <Tab
                label={this.cbContext.translatorService.Tranlate('MENU_ADMINISTRATION_AUCTIONS', 'Administrare licitatii')}
                />
            </Tabs>
          </AppBar>

          <div>
            <TabPanel value={this.state.selectedTab} index={0}>
              <div className="m-3">
                {this.renderAdminAuctionsButtons()}
                <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                  <ScaleLoader color={'var(--primary)'} loading={this.state.isLoadingExport} />
                </div>
                {this.renderFilters()}
                {this.renderSearchButtons()}
                {this.state.isLoadingAuctions ? (
                  <Loader />
                ) : (
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                      title=""
                      data={this.state.auctionsList.auctions}
                      columns={this.getColumns()}
                      options={this.tableOptions()}
                    />
                  </MuiThemeProvider>
                )}       
              </div>
            </TabPanel>
          </div>
        </Card> 
        {this.state.selectedAuction ? (
          <Dialog
            onClose={this.closeDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isDialogOpen}
            fullScreen={true}
            fullWidth={true}
            >
            <DialogTitle
              id="customized-dialog-title"
              style={{
                color: '#ffffff',
                backgroundColor: '#1F2857',
                textAlign: 'center'
              }}
            >
              <span style={{ fontSize: '20px', lineHeight: '24px' }}>
                {this.cbContext.translatorService.Tranlate('MAVO_VEHICLE_AUCTION_DETAILS', 'Detalii licitatie')}
              </span>
              <IconButton
                aria-label="close"
                className="position-absolute"
                style={{ right: 12, top: 12, color: '#ffffff' }}
                onClick={this.closeDialog}
                size={'small'}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
              {this.state.selectedAuction === null ? null : (
                <MavoAuctionDetailsForm
                  mavoVehicleAuction={this.state.selectedAuction}
                  closeDialog={this.closeDialog} 
                />
              )}
            </DialogContent>
          </Dialog>
        ) : null }

         <Modal open={this.state.isOpen} onClose={this.handleCloseModal}>
            <Dialog open={this.state.isOpen} onClose={this.handleCloseModal}>
              <DialogContent>
                <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                  {`${this.cbContext.translatorService.Tranlate(
                    'AUCTION_CLOSED_CONFIRMATION_MESSAGE',
                    'Sunteti sigur ca doriti sa inchideti licitatia?'
                  )} `}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={this.closeAuction}
                >
                  {this.cbContext.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_YES', 'Da')}
                </Button>
                <Button onClick={this.handleCloseModal}>
                  {this.cbContext.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'Nu')}
                </Button>
              </DialogActions>
            </Dialog>
          </Modal>
      </React.Fragment>
    );
  }
}
export default connect(
  (state: ApplicationState) => ({
    appState: state.app,
    mavoVehicleState: state.mavoVehicle,
    mavoVehicleAuctionState: state.mavoVehicleAuction
  }),
{ ...MavoVehicleActionCreators, ...VehicleAuctionActionCreators }
)(withSnackbar(MavoVehiclesAdminAuctions as any));