import React from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface IPreviewAttachmentState {
  currentIndex: number,
  scale: number,
}

interface IPreviewAttachmentProps {
  loadMavoAttachmentData: { url: string; extension: string | undefined; }[],
  currentIndex: number
}

class PreviewAttachment extends React.PureComponent<
  IPreviewAttachmentProps,
  IPreviewAttachmentState
> {

  state = {
    currentIndex: this.props.currentIndex,
    scale: 1,
  } as IPreviewAttachmentState;


  nextImage = () => {
    this.setState({
      currentIndex: (this.state.currentIndex + 1) % this.props.loadMavoAttachmentData?.length
    });
  };

  prevImage = () => {
    this.setState({
      currentIndex: this.state.currentIndex === 0 ? this.props.loadMavoAttachmentData?.length - 1 : this.state.currentIndex - 1
    });

  };

  public render() {
    return (
      <div>
        <button
          className='navigateToGalleryButton'
          onClick={this.prevImage}
          style={{ left: '0px' }}
        >
          ‹
        </button>
        <button
          className='navigateToGalleryButton'
          onClick={this.nextImage}
          style={{ right: '0px' }}
        >
          ›
        </button>
        <TransformWrapper
          initialScale={1}
          minScale={1}
          maxScale={5}
          wheel={{ step: 0.1 }}
          doubleClick={{ step: 1 }}
        >
          {({ zoomIn, zoomOut }) => (
            <>
              <div
                style={{
                  position: "absolute",
                  bottom: "20px",
                  right: "20px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <button
                  onClick={() => zoomIn()}
                  className='zoomGalleryButton'
                >
                  +
                </button>
                <button
                  onClick={() => zoomOut()}
                  className='zoomGalleryButton'
                >
                  −
                </button>
              </div>

              <TransformComponent>
                <img
                  src={this.props.loadMavoAttachmentData[this.state.currentIndex].url}
                  alt="Preview"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                  }}
                />
              </TransformComponent>
            </>
          )
          }
        </TransformWrapper>
      </div>
    );
  }
}

export default PreviewAttachment
