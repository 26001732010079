import * as React from 'react';
import { ApplicationState, AppState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import {
  Card,
  Grid,
  Tab,
  Tabs,
  AppBar,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent
} from '@material-ui/core';
import TabPanel from '../_shared/Tabs';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import { IReferential } from '../../interfaces/IReferential';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  MavoVehicleAuctionsFilters,
  MavoVehicleAuctionsList,
  MavoVehicleAuctionOffer,
  MavoVehicleAuction
} from '../../interfaces/Vehicle';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Loader from '../Loader';
import noop from 'lodash/noop';
import _ from 'lodash';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { MavoVehicleState } from '../../store/reducers/MavoVehicle';
import NumberFormat from 'react-number-format';
import { VehicleAuctionActionCreators } from '../../store/actions/VehicleAuction';
import CloseIcon from '@material-ui/icons/Close';
import MavoVehicleDetailsViewForm from './MavoVehicleDetailsViewForm';
import {MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import RealTimeDate from './RealTimeDate';
import AddAuctionOfferDialog from './AddAuctionOfferDialog';
import { RoleClaimsEnum } from '../../helpers/Constants';

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

type MavoVehiclesAuctionsProps = {
  appState: AppState;
  mavoVehicleState: MavoVehicleState;
  mavoVehicleAuctionState: MavoVehicleAuction;
} &
  typeof MavoVehicleActionCreators & typeof VehicleAuctionActionCreators &
  ProviderContext &
  RouteComponentProps;

interface IMavoVehiclesAuctionsState {
  auctionsList: MavoVehicleAuctionsList;
  makeEntities: IReferential[];
  selectedMakeEntities: IReferential[];
  modelEntities: IReferential[];
  selectedModelEntities: IReferential[];
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
  minPrice: number | null;
  maxPrice: number | null;
  selectedTab: number;
  isLoading: boolean;
  isLoadingAuctions: boolean;
  count: number;
  page: number;
  pageSize: number;
  vin: string;
  selectedAuction: MavoVehicleAuction | null;
  executing: boolean;
  isDialogOpen: boolean;
  isOfferDialogOpen: boolean;
  mavoVehicleAuctionOffer: MavoVehicleAuctionOffer;
  isOpen: boolean;
}

class MavoVehiclesAuctions extends React.PureComponent<MavoVehiclesAuctionsProps, IMavoVehiclesAuctionsState> {
  private cbContext!: AppContext;
  static contextType = ApplicationContext;

  state = {
    auctionsList: {} as MavoVehicleAuctionsList,
    makeEntities: [],
    selectedMakeEntities: [],
    modelEntities: [],
    selectedModelEntities: [],
    selectedTab: 0,
    isLoading: false,
    isLoadingAuctions: false,
    count: 1,
    page: 0,
    pageSize: 10,
    vin: '',
    selectedStartDate: null,
    selectedEndDate: null,
    selectedAuction: null,
    executing: false,
    minPrice: null,
    maxPrice: null,
    isDialogOpen: false,
    isOfferDialogOpen: false,
    mavoVehicleAuctionOffer: {
      mavoVehicleAuctionId: 0,
      id: 0
    } as MavoVehicleAuctionOffer,
    isOpen: false
  } as IMavoVehiclesAuctionsState;

  public async componentDidMount() {
    const mavoVehiclesAuctionsFilters = {
      makeIds: [],
      modelIds: [],
      startDate: null,
      endDate: null,
      page: this.state.page + 1,
      pageSize: this.state.pageSize,
      vin: '',
      minPrice: null,
      maxPrice: null
    } as MavoVehicleAuctionsFilters;

    this.setState({
      isLoading: true
    });

    const [auctionsList] = await Promise.all([
      this.cbContext.vehicleService.GetAuctions(mavoVehiclesAuctionsFilters)
    ]);

    this.setState(
      {
        auctionsList: auctionsList,
        count: auctionsList.total,
        page: auctionsList.page - 1
      },
      async () => {
        await this.reloadFilters();
      }
    );
  }

  loadAuctions = async (page: number, rowsPerPage: number) => {
    this.setState({
      isLoadingAuctions: true,
      selectedAuction: null
    });

    const mavoVehiclesAuctionsFilters = {
      makeIds: this.state.selectedMakeEntities.map((item) => item.id),
      modelIds: this.state.selectedModelEntities.map((item) => item.id),
      startDate: this.state.selectedStartDate
      ? new Date(this.state.selectedStartDate.setHours(0, 0, 0, 0))
      : null,
      endDate: this.state.selectedEndDate
      ? new Date(this.state.selectedEndDate.setHours(23, 59, 59, 999))
      : null,
      page: page + 1,
      pageSize: rowsPerPage,
      vin: this.state.vin,
      minPrice: this.state.minPrice,
      maxPrice: this.state.maxPrice
    } as MavoVehicleAuctionsFilters;

    const auctionsList = await this.cbContext.vehicleService.GetAuctions(mavoVehiclesAuctionsFilters);
    
    this.setState({
      auctionsList: auctionsList,
      page: auctionsList.page - 1,
      pageSize: rowsPerPage,
      count: auctionsList.total,
      isLoadingAuctions: false
    });
  };

  reloadFilters = async () => {
    const filters = await this.cbContext.vehicleService.GetAuctionsFilters();
    this.setState({
      makeEntities: filters.makeList,
      modelEntities: filters.modelList,
      isLoading: false
    });
  };

  makeSelectedAuction =  (isSelected: boolean, id: number) => {
    const auctions = _.cloneDeep(this.state.auctionsList.auctions);
    const auction = auctions.find((item) => item.id === id);
    if (auction) {
      auction.isSelected = isSelected;
    }

    if (isSelected) {
      auctions.forEach((item) => {
        if (item.id !== id) {
          item.isSelected = false;
        }   
      });
    }
    this.setState({
      auctionsList: {
        ...this.state.auctionsList,
        auctions: auctions
      },
      selectedAuction: isSelected ? (auction ? auction : null): null
    });
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableCell: {
        head: {
          paddingLeft: "2px",
          paddingRight: "2px",
          paddingTop: "2px",
          paddingBottom: "2px"
        },
      },
      MUIDataTableBodyCell: {
        stackedCommon: {
          paddingLeft: "2px",
          paddingRight: "2px",
          paddingTop: "0px",
          paddingBottom: "0px",
          height: "40px !important"
        }
      },
      MuiCheckbox: {
        root: {
          padding: "0px", // Adjust the padding to make the checkbox smaller
        },
        colorSecondary: {
          '&.Mui-checked': {
            color: "#3d4977 !important",
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.03) !important'
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.03) !important'
          }
        }
      }
    } as any
  });

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'id',
        label: ' ',
        options: {
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            const auction = this.state.auctionsList.auctions.find((item) => item.id === tableMeta.rowData[0]);
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    <FormControlLabel
                      className="m-2"
                      control={
                        <Checkbox
                          checked={auction!.isSelected || false}
                          onChange={(e) => {
                            this.makeSelectedAuction(e.target.checked, auction!.id);
                          }}
                        ></Checkbox>
                        }
                        label=''
                    />
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'mavoVehicle.vin',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_VIN_CODE', 'VIN'),
        
      },
      {
        name: 'mavoVehicle.make.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MAKE', 'Marca'),
        options: {
          sort: false
        }
      },
      {
        name: 'mavoVehicle.model.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MODEL', 'Model'),
        options: {
          sort: false
        }
      },      
      {
        name: '',
        label: this.cbContext.translatorService.Tranlate(
          'AUCTION_ADMIN_HAS_OFFERS',
          'Oferte'
        ),
        options: {
          sort: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            const auction = this.state.auctionsList.auctions.find((item) => item.id === tableMeta.rowData[0]);
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div>
                    {auction!.mavoVehicleAuctionOffers.length > 0 ? 
                        this.cbContext.translatorService.Tranlate("AUCTION_ADMIN_HAS_OFFERS_YES", "Da")
                       : this.cbContext.translatorService.Tranlate("AUCTION_ADMIN_HAS_OFFERS_NO", "Nu")
                    }
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'startPrice',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_AUCTION_START_PRICE', 'Pret start'),
        options: {
          sort: false
        }
      },
      {
        name: 'currentPrice',
        label: this.cbContext.translatorService.Tranlate(
          'AUCTION_ADMIN_CURRENT_PRICE',
          'Pret curent'
        ),
        options: {
          sort: false
        }
      },
      {
        name: 'startDate',
        label: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_AUCTION_START_DATE',
          'Data start'
        ),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    {value
                      ? moment
                          .utc(value)
                          .local()
                          .format(this.props.appState.dateFormat)
                      : null}
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'endDate',
        label: this.cbContext.translatorService.Tranlate(
          'ADMIN_AUCTION_END_DATE',
          'Data stop'
        ),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    {value
                      ? moment(value)
                        .format(this.props.appState.longDateFormat)
                      : null}
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'mavoVehicle.provenance.type',
        label: this.cbContext.translatorService.Tranlate(
          'MENU_NOMENCLATURES_TAB_PROVENANCE',
          'Provenienta'
        ),
        options: {
          sort: false
        }
      },
      {
        name: 'mavoVehicle.location',
        label: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHILES_VEHICLE_LOCATION',
          'Locatie'
        ),
        options: {
          sort: false
        }
      },
      {
        name: '',
        label: this.cbContext.translatorService.Tranlate('MAVO_AUCTION_REMAINING_TIME', 'Timp ramas'),
        options: {
          sort: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            const auction = this.state.auctionsList.auctions.find((item) => item.id === tableMeta.rowData[0]);
            return (
              <span>   
                <RealTimeDate 
                  endDate={auction!.endDate}
                  showLastOffer={false}
                  auctionStatus={auction!.auctionStatus}
                />
              </span>
            );
          }
        }
      }
    ];

    return columns;
  };

  sortFunction = (a: any, b: any) => {
    return a.order - b.order;
  };

  handleMakeEntitiesChange = (newValue: any[]) => {
    const selectedModels = [...this.state.selectedModelEntities];
    const selectedMakeIds = newValue.map(({ id }) => id);

    const models = selectedModels.filter(({ dependencyId }) =>
      selectedMakeIds.includes(dependencyId)
    );

    this.setState(
      {
        selectedMakeEntities: newValue,
        selectedModelEntities: models
      }
    );
  };

  handleModelEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedModelEntities: newValue
      }
    );
  };

  handleMinPriceChange = (value: string) => {
    this.setState({
      minPrice: value ? parseFloat(value) : null
    });
  };

  handleMaxPriceChange = (value: string) => {
    this.setState({
      maxPrice: value ? parseFloat(value) : null
    });
  };


  handleStartDateChange = async (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }
    this.setState(
      {
        selectedStartDate: date!.toDate(),
        isLoading: false
      }
    );
  };

  handleEndDateChange = async (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }

    this.setState(
      {
        selectedEndDate: date!.toDate(),
        isLoading: false
      }
    );
  };


  renderSearchButtons() {
    return (
      <div className="row m-0 mt-3">
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => this.loadAuctions(this.state.page, this.state.pageSize)}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_SEARCH',
              'Cautare'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => this.resetFilters()}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_RESET',
              'Reset'
            )}
          </Button>
        </div>
      </div>

    );
  }

  openAddOfferDialog = () => {
    if (!this.state.selectedAuction) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('PLEASE_SELECT_LINE', 'Va rugam sa selectati o linie!'), {
        variant: 'warning'
      });
      return;
    }

    this.setState({
      isOfferDialogOpen: true
    });
  }

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  renderAuctionsButtons() {
    return (
      <div className="row m-0 mt-3">
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              if (!this.state.selectedAuction) {
                this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('PLEASE_SELECT_LINE', 'Va rugam sa selectati o linie!'), {
                  variant: 'warning'
                });
                return;
              }

              this.setState({
                isDialogOpen: true,
                selectedAuction: this.state.selectedAuction!
              });
            }}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_VIEW',
              'Vizualizeaza'
            )}
          </Button>
        </div>
        {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? null : (
          <div className='mb-3 mr-2'>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={this.openAddOfferDialog}
            >
              {this.cbContext.translatorService.Tranlate(
                'MAVO_VEHICLES_VEHICLE_BID',
                'Liciteaza'
              )}
            </Button>
          </div>
        )}
      </div>

    );
  }

  resetFilters = async () => {
    this.setState({
      selectedMakeEntities: [],
      selectedModelEntities: [],
      selectedStartDate: null,
      selectedEndDate: null,
      vin: '',
      minPrice: null,
      maxPrice: null
    }, async () => {
      await this.loadAuctions(this.state.page, this.state.pageSize);
    });
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };
  

  renderFilters = () => {
    const selectedMakes = this.state.selectedMakeEntities;
    let models = this.state.modelEntities;

    if (selectedMakes.length) {
      const makeIds = selectedMakes.map(({ id }) => id);
      models = this.state.modelEntities.filter(({ dependencyId }) => {
        return makeIds.includes(dependencyId!);
      });
    }

    return (
      <div className="my-1">
        <ValidatorForm onSubmit={noop}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                id="make"
                size="small"
                className="m-0 mt-1"
                multiple
                options={this.state.makeEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedMakeEntities}
                onChange={(e: any, newValue: any | null) => this.handleMakeEntitiesChange(newValue)}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="makeEntities"
                    value={this.state.selectedMakeEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLES_VEHICLE_MAKE',
                      'Marca'
                    )}
                    fullWidth
                  />
                )}
              />

              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  className="m-0 mt-1"
                  disableToolbar
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  label={this.cbContext.translatorService.Tranlate('AUCTIONS_FILTER_START_DATE', 'Data start')}
                  value={this.state.selectedStartDate}
                  onChange={this.handleStartDateChange}
                  autoOk={true}
                  // minDate={activeButton === 1 ? new Date() : this.state.minDate}
                />
              </MuiPickersUtilsProvider> 

              <TextValidator
                fullWidth
                name="minPrice"
                className="m-0 mt-1"
                id="minPrice"
                value={this.state.minPrice || ''}
                onChange={(e: any) => this.handleMinPriceChange(e.target.value)}
                label={this.cbContext.translatorService.Tranlate(
                  'AUCTIONS_FILTER_MIN_PRICE',
                  'Pret minim'
                )}
                validators={['required', 'minNumber:1']}
                errorMessages={[
                  this.cbContext.translatorService.Tranlate(
                    'AUCTIONS_FILTER_MIN_PRICE',
                    'Pret minim'
                  )
                ]}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />   

              <div className="mt-1">
                <TextValidator
                  fullWidth
                  id="vin"
                  name="color"
                  placeholder={this.cbContext.translatorService.Tranlate(
                    'MAVO_VEHICLES_VEHICLE_VIN',
                    'Vin'
                  )}
                  value={this.state.vin}
                  onChange={(e: any) => {
                    this.setState({
                      vin: e.target.value
                    });
                  }}
                  validators={['required']}
                  label={this.cbContext.translatorService.Tranlate('CALCULATION_DETAILS_VIN', 'Vin')}
                />
              </div>
               
               
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                id="model"
                size="small"
                className="m-0 mt-1"
                multiple
                options={models.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedModelEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleModelEntitiesChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="modelEntities"
                    value={this.state.selectedModelEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLES_VEHICLE_MODEL',
                      'Model'
                    )}
                    fullWidth
                  />
                )}
              />

              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  className="m-0 mt-1"
                  disableToolbar
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  label={this.cbContext.translatorService.Tranlate('AUCTIONS_FILTER_END_DATE', 'Data stop')}
                  value={this.state.selectedEndDate}
                  onChange={this.handleEndDateChange}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>

              <TextValidator
                fullWidth
                name="maxPrice"
                className="m-0 mt-1"
                id="maxPrice"
                value={this.state.maxPrice || ''}
                onChange={(e: any) => this.handleMaxPriceChange(e.target.value)}
                label={this.cbContext.translatorService.Tranlate(
                  'AUCTIONS_FILTER_MAX_PRICE',
                  'Pret maxim'
                )}
                validators={['required', 'minNumber:1']}
                errorMessages={[
                  this.cbContext.translatorService.Tranlate(
                    'AUCTIONS_FILTER_MAX_PRICE',
                    'Pret maxim'
                  )
                ]}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />   
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    );
  };

  onRowDoubleClick = (
    dataIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => {
    if (columnIndex == 1) {
      return;
    }
    const auction = this.state.auctionsList.auctions[dataIndex];
    this.setState({
      isDialogOpen: true,
      selectedAuction: auction!
    });
  };

  tableOptions = () => {
    return {
      sort: true,
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.cbContext.translatorService),
      elevation: 0,
      serverSide: true,
      count: this.state.count,
      rowsPerPage: this.state.pageSize,
      page: this.state.page,
      onCellClick: (colData: any, cellMeta: any) => {
        if (cellMeta && cellMeta.colIndex !== null && cellMeta.rowIndex !== null) {
          // Set up a double-click event listener on the cell
          if (cellMeta.event.detail === 2) {
            this.onRowDoubleClick(cellMeta.dataIndex, cellMeta.rowIndex, cellMeta.colIndex);
          }
        }
      },
      onTableChange: async (action: any, tableState: any) => {
        switch (action) {
          case 'changePage':
            await this.loadAuctions(tableState.page, tableState.rowsPerPage);
            break;
          case 'changeRowsPerPage':
            await this.loadAuctions(tableState.page, tableState.rowsPerPage);
            break;
          case 'sort':
            break;
          default:
        }
      }
    };
  };

  importedTableOptions = () => {
    return {
      sort: true,
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.cbContext.translatorService),
      elevation: 0,
      count: this.state.count,
      rowsPerPage: this.state.pageSize,
      page: this.state.page
    };
  };

  closeDialog = async () => {
    this.setState(
      {
        isDialogOpen: false,
        selectedAuction: null
      },
      async () => {
        this.loadAuctions(this.state.page, this.state.pageSize);
      }
    );
  };
  
  closeOfferDialog = async () => {
    this.setState(
      {
        isOfferDialogOpen: false,
        selectedAuction: null,
        isLoadingAuctions: true
      },
      async () => {
        this.loadAuctions(this.state.page, this.state.pageSize);
      }
    );
  };

  goToUserAuctions = () => {
    this.props.history.push('/user/auctions');
  }

  handleOpenConfirmationDialog = () => {
    this.setState({
      isOpen: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false
    });
  };

  public render() {
    this.cbContext = this.context as AppContext;

    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <Card>
          <AppBar position="static" elevation={0} color="secondary">
            <Tabs
              TabIndicatorProps={{
                style: {
                  height: '4px'
                }
              }}
              value={this.state.selectedTab}
              variant="fullWidth"
              centered={true}
              indicatorColor="primary"
              aria-label="simple tabs example"
            >
              <Tab
                label={this.cbContext.translatorService.Tranlate('MENU_ACTIVE_AUCTIONS', 'Licitatii active')}
                />
            </Tabs>
          </AppBar>

          <div>
            <TabPanel value={this.state.selectedTab} index={0}>
              <div className="m-3">
                {this.renderAuctionsButtons()}
                {this.renderFilters()}
                {this.renderSearchButtons()}
                {this.state.isLoadingAuctions ? (
                  <Loader />
                ) : (
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                      title=""
                      data={this.state.auctionsList.auctions}
                      columns={this.getColumns()}
                      options={this.tableOptions()}
                    />
                  </MuiThemeProvider>
                )}       
              </div>
            </TabPanel>
          </div>
        </Card>
        {this.state.selectedAuction ? (
          <Dialog
            onClose={this.closeDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isDialogOpen}
            fullScreen={true}
            fullWidth={true}
            >
            <DialogTitle
              id="customized-dialog-title"
              style={{
                color: '#ffffff',
                backgroundColor: '#1F2857',
                textAlign: 'center'
              }}
            >
              <span style={{ fontSize: '20px', lineHeight: '24px' }}>
                {this.cbContext.translatorService.Tranlate('MAVO_VEHICLE_VIEW', 'Fisa Tehnica (Fisa de Evaluare)')}
              </span>
              <IconButton
                aria-label="close"
                className="position-absolute"
                style={{ right: 12, top: 12, color: '#ffffff' }}
                onClick={this.closeDialog}
                size={'small'}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
              {this.state.selectedAuction === null ? null : (
                <MavoVehicleDetailsViewForm 
                  mavoVehicleAuction={this.state.selectedAuction}
                  closeDialog={this.closeDialog} 
                  goToUserAuctions={this.goToUserAuctions}
                />
              )}
            </DialogContent>
          </Dialog>
        ) : null }
        {this.state.isOfferDialogOpen && this.state.selectedAuction ? (
          <Dialog
            onClose={this.closeOfferDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isOfferDialogOpen}
            fullScreen={true}
            fullWidth={true}
            >
            <DialogTitle
              id="customized-dialog-title"
              style={{
                color: '#ffffff',
                backgroundColor: '#1F2857',
                textAlign: 'center'
              }}
            >
              <span style={{ fontSize: '20px', lineHeight: '24px' }}>
                {this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_AUCTION_OFFER', 'Oferta')}
              </span>
              <IconButton
                aria-label="close"
                className="position-absolute"
                style={{ right: 12, top: 12, color: '#ffffff' }}
                onClick={this.closeOfferDialog}
                size={'small'}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
              <AddAuctionOfferDialog
                selectedAuction={this.state.selectedAuction}
                closeDialog={this.closeOfferDialog}
                goToUserAuctions={this.goToUserAuctions}
              />
            </DialogContent>
          </Dialog>
        ) : null }
      </React.Fragment>
    );
  }
}
export default connect(
  (state: ApplicationState) => ({
    appState: state.app,
    mavoVehicleState: state.mavoVehicle,
    mavoVehicleAuctionState: state.mavoVehicleAuction
  }),
{ ...MavoVehicleActionCreators, ...VehicleAuctionActionCreators }
)(withSnackbar(MavoVehiclesAuctions as any));