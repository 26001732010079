import * as React from 'react';
import { Button, CircularProgress } from "@material-ui/core";
import { AppContext, ApplicationContext } from "../../context/Contexts";
import { CreateEInvoiceRequest, DeclineMavoVehicleOrderRequest, MavoVehicleCreateInvoiceRequestDto, MavoVehicleCreateNoticeRequestDto, MavoVehicleOrderRCICloseRequest } from '../../interfaces/Vehicle';
import { InvoiceTypeCodeEnum, MavoOrderStatusCodeEnum, MavoVehicleProvenanceType, ReportTypeCode } from '../../helpers/Constants';
import { withSnackbar } from 'notistack';
import {  ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { IMavoOrdersButtonsProps, MavoOrderButtonsProps } from '../cases/vehicleDetails/IMavoOrdersButtonsProps';
import FileSaver from 'file-saver';

interface IOrdersButtonsState {
  isLoading: boolean;
  executing: boolean;
  orderConfirmationLoading: boolean;
  cancelOrderLoading: boolean;
  orderRefuseLoading: boolean;
  printPaymentNoticeLoading: boolean;
  generateInvoiceLoading: boolean;
  rciCloseOrderLoading: boolean;
}

class OrdersButtons extends React.PureComponent<
  IMavoOrdersButtonsProps,
  IOrdersButtonsState
> {

  state = {
    isLoading: false,
    executing: false,
    orderConfirmationLoading: false,
    cancelOrderLoading: false,
    orderRefuseLoading: false,
    printPaymentNoticeLoading: false,
    generateInvoiceLoading: false,
    rciCloseOrderLoading: false
  } as IOrdersButtonsState;

  private cbContext!: AppContext;
  static contextType = ApplicationContext;

  handleOrderConfirm = async () => {
    if (!this.props.selectedOrder?.orderStatus) return;
    if (this.props.selectedOrder?.orderStatus.code === MavoOrderStatusCodeEnum.CONFIRMAT.toString()) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ORDER_CONFIRM_ERR_MSG', 'Nu puteti confirma o comanda deja confirmata!'), {
        variant: 'warning' 
      });
      return;
    }
    if (this.props.selectedOrder?.orderStatus.code === MavoOrderStatusCodeEnum.TRANSMIS.toString()) {
      try {
        this.setState({ orderConfirmationLoading: true });

        const request = {
          mavoVehicleOrderId: this.props.selectedOrder.id,
          userId: this.props.selectedOrder.userId ?? null
        } as MavoVehicleCreateNoticeRequestDto;

        await this.cbContext.vehicleService.ConfirmMavoVehicleOrder(ReportTypeCode.RENAULT_AVIZ_PLATA, request);

        this.props.loadOrders(this.props.page, this.props.pageSize);

        this.setState({ orderConfirmationLoading: false });

        this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch {
        this.setState({ executing: false, orderConfirmationLoading: false });
        this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  }
  
  handleCancelConfirmation = async () => {
    if (!this.props.selectedOrder?.orderStatus) return;

    if (this.props.selectedOrder?.orderStatus.code === MavoOrderStatusCodeEnum.TRANSMIS.toString()) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ORDER_CANCEL_CONFIRM_ERR_MSG', 'Nu a fost generat Aviz de Plata!'), {
        variant: 'warning'
      });
      return;
    }

    try {
      this.setState({ cancelOrderLoading: true });
      const request = {
          mavoVehicleOrderId: this.props.selectedOrder.id,
          userId: this.props.selectedOrder.userId ?? null
        } as MavoVehicleCreateNoticeRequestDto;
        
      await this.cbContext.vehicleService.CancelNoticeMavoVehicle(ReportTypeCode.RENAULT_AVIZ_PLATA, request);

      this.props.loadOrders(this.props.page, this.props.pageSize);

      this.setState({ cancelOrderLoading: false });

      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch {
      this.setState({ executing: false, cancelOrderLoading: false });

      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  
  }

  handleOrderRefuse = async () => {
    if (!this.props.selectedOrder?.orderStatus) return;
    if (this.props.selectedOrder?.orderStatus.code === MavoOrderStatusCodeEnum.CONFIRMAT.toString()) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ORDER_REFUSE_ERR_MSG', 'Vehicul in curs de facturare!'), {
        variant: 'warning'
      });
      return;
    }
    try {
      this.setState({ orderRefuseLoading: true });

      const request = {
        mavoVehicleOrderId: this.props.selectedOrder.id
      } as DeclineMavoVehicleOrderRequest;

      await this.cbContext.vehicleService.DeclineMavoVehicleOrder(request);

      this.props.loadOrders(this.props.page, this.props.pageSize);

      this.setState({ orderRefuseLoading: false });

      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch {
      this.setState({ executing: false, orderRefuseLoading: false });
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  handlePrintPaymentNotice = async () => {
    if (!this.props.selectedOrder?.orderStatus) return;
    if (this.props.selectedOrder?.orderStatus.code === MavoOrderStatusCodeEnum.TRANSMIS.toString()) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ORDER_PRINT_PAYMENT_NOTICE_ERR_MSG', 'Vehicul fara Aviz de Plata !'), {
        variant: 'warning'
      });
      return;
    }

    try {
      this.setState({ printPaymentNoticeLoading: true });

      const fileBlob = await this.cbContext.vehicleService.PrintMavoVehicleNotice(this.props.selectedOrder.id);

      this.setState({ printPaymentNoticeLoading: false });

      const file = new File([fileBlob], this.cbContext.translatorService.Tranlate('PRINT_NOTICE_NAME', 'Avizare plata bancara') + '.pdf', { type: 'application/pdf' });
      
      FileSaver.saveAs(fileBlob, "AvizarePlataBancara" + '.pdf');

      const url = URL.createObjectURL(file);
      window.open(url, '_blank');
    } catch {
      this.setState({ executing: false, printPaymentNoticeLoading: false });

      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  handleInvoiceGenerate = async () => {
    if (!this.props.selectedOrder?.orderStatus) return;
    if (this.props.selectedOrder?.orderStatus.code === MavoOrderStatusCodeEnum.TRANSMIS.toString()) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ORDER_PRINT_PAYMENT_NOTICE_ERR_MSG', 'Vehicul fara Aviz de Plata !'), {
        variant: 'warning'
      });
      return;
    }

    try {
      this.setState({ generateInvoiceLoading: true });

      const request = {
        mavoVehicleOrderId: this.props.selectedOrder.id,
        userId: this.props.selectedOrder.userId ?? null
      } as MavoVehicleCreateInvoiceRequestDto;
      const response = await this.cbContext.vehicleService.GenerateInvoiceReport(ReportTypeCode.RENAULT_FACTURA_LIVRARE, request);

      if (response.existingValidInvoice) {
        this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ORDER_VALID_GENERATE_INVOICE_ERR_MSG', 'Factura valida existenta!'), {
          variant: 'warning'
        });
        this.setState({ generateInvoiceLoading: false });

        return;
      }

      const eInvoiceResponse = await this.cbContext.vehicleService.CreateEInvoice({
        orderId: this.props.selectedOrder.id,
        invoiceId: response.id,
        invoiceTypeCode: InvoiceTypeCodeEnum.FACTURA.toString()
      } as CreateEInvoiceRequest);

      if (eInvoiceResponse.statusMessage) {
        this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate(eInvoiceResponse.statusMessage, eInvoiceResponse.statusMessage), {
          variant: 'warning'
        });
      }

      this.props.loadOrders(this.props.page, this.props.pageSize);

      this.setState({ generateInvoiceLoading: false });

      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch {
      this.setState({ executing: false, generateInvoiceLoading: false });

      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  handleRCICloseOrder = async () => {
    if (!this.props.selectedOrder) return;

    try {
      this.setState({ rciCloseOrderLoading: true });

      const request = {
        mavoVehicleOrderId: this.props.selectedOrder.id
      } as MavoVehicleOrderRCICloseRequest;

      await this.cbContext.vehicleService.CloseMavoVehicleOrderRCI(request);

      this.props.loadOrders(this.props.page, this.props.pageSize);

      this.setState({ rciCloseOrderLoading: false });

      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch {
      this.setState({ executing: false, rciCloseOrderLoading: false });

      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  orderButtonDisabled = () => {
    if (this.state.orderConfirmationLoading || this.state.cancelOrderLoading || this.state.orderRefuseLoading || this.state.printPaymentNoticeLoading || this.state.generateInvoiceLoading || this.state.rciCloseOrderLoading) {
      return true;
    }

    if (this.props.selectedOrder && this.props.selectedOrder.mavoVehicle!.provenance!.type === MavoVehicleProvenanceType.DVS) {
      return false;
    }

    return true;
  }

  rciButtonDisabled = () => {
    if (this.state.orderConfirmationLoading || this.state.cancelOrderLoading || this.state.orderRefuseLoading || this.state.printPaymentNoticeLoading || this.state.generateInvoiceLoading || this.state.rciCloseOrderLoading) {
      return true;
    }

    if (this.props.selectedOrder && this.props.selectedOrder.mavoVehicle!.provenance!.type === MavoVehicleProvenanceType.RCI) {
      return false;
    }

    return true;
  }

  render() {
    this.cbContext = this.context as AppContext;

    return (
      <div className="row m-0 mt-3">
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={this.orderButtonDisabled()}
            onClick={this.handleOrderConfirm}
          >
            {this.state.orderConfirmationLoading && (
              <CircularProgress
                size={16}
                style={{ marginRight: 5 }}
              />
            )}
            {this.cbContext.translatorService.Tranlate(
              'MAVO_ORDER_CONFIRM_ORDER',
              'Confirma comanda'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={this.orderButtonDisabled()}
            onClick={this.handleCancelConfirmation}
          >
            {this.state.cancelOrderLoading && (
                <CircularProgress
                size={16}
                style={{ marginRight: 5 }}
              />
            )}
            {this.cbContext.translatorService.Tranlate(
              'MAVO_ORDER_CANCEL_ORDER_CONFIRMATION',
              'Anuleaza confirmare comanda'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!this.props.selectedOrder || this.state.orderRefuseLoading}
            onClick={this.handleOrderRefuse}
          >
            {this.state.orderRefuseLoading && (
              <CircularProgress
                size={20}
                style={{ marginRight: 8 }}
              />
            )}
            {this.cbContext.translatorService.Tranlate(
              'MAVO_ORDER_REFUSE_ORDER',
              'Refuza comanda'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={this.orderButtonDisabled()}
            onClick={this.handlePrintPaymentNotice}
          >
           {this.state.printPaymentNoticeLoading && (
              <CircularProgress
                size={20}
                style={{ marginRight: 8 }}
              />
            )}
            {this.cbContext.translatorService.Tranlate(
              'MAVO_ORDER_PRINT_PAYMENT_ORDER',
              'Tiparire Aviz de Plata'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={this.orderButtonDisabled()}
            onClick={this.handleInvoiceGenerate}
          >
            {this.state.generateInvoiceLoading && (
              <CircularProgress
                size={20}
                style={{ marginRight: 8 }}
              />
            )}
            {this.cbContext.translatorService.Tranlate(
              'MAVO_ORDER_GENERATE_INVOICE',
              'Generare factura'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={this.rciButtonDisabled()}
            onClick={this.handleRCICloseOrder}   
          >
           {this.state.rciCloseOrderLoading && (
              <CircularProgress
                size={20}
                style={{ marginRight: 8 }}
              />
            )}
            {this.cbContext.translatorService.Tranlate(
              'MAVO_ORDER_CLOSE_RCI_ORDER',
              'Inchidere comanda RCI'
            )}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  (
    state: ApplicationState,
    ownProps: MavoOrderButtonsProps
  ) => ({
    appState: state.app,
    ...ownProps
  })
)(withSnackbar(OrdersButtons as any));
